'use client';

import { Button, Icon, SwiperReact, SwiperSlide } from '@theme/components';
import { ShoppableSliderItemType } from './type';
import { Image } from '@akinon/next/components/image';
import './shoppable-slide.scss';
import { ShoppableSliderHotspots } from './slider-hotspots';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Swiper from 'swiper';

export function ShoppableSliderItems({
  slides
}: {
  slides: ShoppableSliderItemType[];
}) {
  const swiperRef = useRef(null);

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  if (!Array.isArray(slides) || !slides.length) return;

  return (
    <SwiperReact
      ref={swiperRef}
      initialSlide={0}
      centeredSlides={true}
      onSlideChange={(swiper: Swiper) => {
        setCurrentSlideIndex(swiper?.activeIndex);
      }}
      slidesPerView={'auto'}
      breakpoints={{
        640: {
          slidesPerView: 1.36,
          spaceBetween: 16
        },
        768: {
          slidesPerView: 1.33828996,
          spaceBetween: 0
        },
        1024: {
          slidesPerView: 1.33828996,
          spaceBetween: 24
        }
      }}
      spaceBetween={0}
      className="shoppable-slider-wrapper 2xl:min-h-[600px]"
    >
      {slides.map((slide, index) => {
        const bannerImage = slide?.kwargs?.value?.desktop_banner_image?.url;
        const mobileBannerImage =
          slide?.kwargs?.value?.mobile_banner_image?.url;

        return (
          <SwiperSlide
            className="leading-[0]"
            key={`shoppable-slider-item-${index}`}
          >
            {bannerImage && (
              <Image
                className="shoppable-image-wrapper hidden h-full w-full lg:block"
                imageClassName="w-full h-full object-cover"
                src={bannerImage}
                alt="shoppable-slider-desktop-image"
                width={1920}
                height={800}
              />
            )}
            {mobileBannerImage && (
              <Image
                className="shoppable-image-wrapper h-full w-full lg:hidden"
                imageClassName="w-full h-full object-cover"
                src={mobileBannerImage}
                alt="shoppable-slider-mobile-image"
                width={490}
                height={250}
              />
            )}
            <Button className="absolute bottom-4 right-4 h-10 w-10 rounded-full p-2 lg:bottom-6 lg:right-6 lg:!h-12 lg:!w-12">
              <Icon
                className="justify-center lg:!text-lg"
                name="plus"
                size={12}
              />
            </Button>
            {slide?.value?.pins_widget_slug && (
              <ShoppableSliderHotspots
                hotspotWidgetSlug={slide.value.pins_widget_slug}
              />
            )}
          </SwiperSlide>
        );
      })}
      <Button
        onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
        className={clsx(
          'hidden !h-8 !min-h-8 !min-w-8 max-w-8  items-center rounded-full',
          'border-0 bg-gray-100 p-0 text-primary outline-0',
          'absolute left-[72px]  top-1/2 z-30 translate-y-1/2 transform',
          { 'lg:grid': currentSlideIndex >= 1 }
        )}
      >
        <Icon className="justify-center" name="chevron-start" size={14} />
      </Button>
      <Button
        onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
        className={clsx(
          'hidden !h-8  !min-h-8 !min-w-8 max-w-8 translate-y-1/2 transform items-center rounded-full',
          'border-0 bg-gray-100 p-0 text-primary outline-0',
          'absolute right-[72px] top-1/2 z-30',
          {
            'lg:grid':
              currentSlideIndex >= 0 && currentSlideIndex < slides.length - 1
          }
        )}
      >
        <Icon className="justify-center" name="chevron-end" size={14} />
      </Button>
    </SwiperReact>
  );
}
