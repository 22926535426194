'use client';
import { Image } from '@akinon/next/components/image';
import { Icon, Link, SwiperReact, SwiperSlide } from '@theme/components';
import { HomeInspirationType } from './type';
import { useInView } from 'react-intersection-observer';
import { useEffect, useMemo, useState } from 'react';
import { pushViewPromotion, pushSelectPromotion } from '@theme/utils/gtm';

const HomeInspirationItem = ({
  item,
  linkClassName = '',
  imageWidth,
  imageHeigth,
  descriptionClassName = 'my-3 text-xl font-medium',
  imageClassName = 'object-cover',
  index
}) => {
  const { ref, inView } = useInView();
  const [viewed, setViewed] = useState(false);

  const promotionEvent = useMemo(
    () => ({
      creative_name: item?.kwargs?.value?.image?.url,
      creative_slot: `Home Inspiration ${index + 1}`,
      promotion_id: `home-inspiration_${index + 1}`,
      promotion_name: item?.value?.description || ''
    }),
    [item, index]
  );

  useEffect(() => {
    if (inView && !viewed) {
      setViewed(true);
      pushViewPromotion(promotionEvent);
    }
  }, [inView, viewed, promotionEvent]);

  return (
    <>
      <Link
        href={item?.value?.url}
        className={linkClassName}
        onClick={() => pushSelectPromotion(promotionEvent)}
      >
        <div ref={ref}>
          <Image
            src={item?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
            alt="home-inspiration"
            imageClassName={imageClassName}
            width={imageWidth}
            height={imageHeigth}
          />
          {item?.value?.description && (
            <p
              {...(item?.value?.description_color && {
                style: { color: item?.value?.description_color }
              })}
              className={descriptionClassName}
            >
              {item?.value?.description}{' '}
              <Icon
                className="inline-block align-middle"
                size={20}
                name="arrow-right"
              />
            </p>
          )}
        </div>
      </Link>
    </>
  );
};

export default function HomeInspiration({
  data
}: {
  data: HomeInspirationType;
}) {
  if (Object.keys(data || {}).length === 0) return null;

  const {
    title,
    title_color,
    button_url,
    button_text,
    button_text_color,
    home_inspiration_items
  } = data?.attributes ?? {};

  if (home_inspiration_items && home_inspiration_items.length === 0)
    return null;

  return (
    <div className="border-t border-lightBlue py-10 md:p-[72px]">
      <div className="mb-6 flex flex-col items-center justify-center gap-4 md:mb-[55px]">
        <h2
          {...(title_color?.value && { style: { color: title_color.value } })}
          className="px-8 text-center text-2xl leading-[1.33] tracking-[-0.4px] md:text-[36px] md:leading-[1.3]"
        >
          {title?.value}
        </h2>
        <Link
          href={button_url?.value}
          className="flex items-center justify-center gap-1 text-sm md:text-base"
        >
          <div
            {...(button_text_color?.value && {
              style: { color: button_text_color.value }
            })}
            className="border-b border-primary pb-0.5 text-sm lg:text-base"
          >
            {button_text?.value}
          </div>
          <Icon name={'arrow-right'} />
        </Link>
      </div>

      <div className="block md:hidden">
        <SwiperReact
          spaceBetween={16}
          slidesPerView={1.4}
          breakpoints={{
            575: {
              slidesPerView: 2
            }
          }}
        >
          {home_inspiration_items.length > 0 &&
            home_inspiration_items?.map((item, index) => (
              <SwiperSlide
                key={index}
                className="flex-shrink-0 first:pl-4 last:pr-4"
              >
                <HomeInspirationItem
                  item={item}
                  imageWidth={282}
                  imageHeigth={352}
                  imageClassName="object-cover min-w-[232px] min-h-[352px]"
                  descriptionClassName="mt-3 text-base font-medium"
                  index={0}
                />
              </SwiperSlide>
            ))}
        </SwiperReact>
      </div>

      <div className="container hidden md:block">
        <div className="grid grid-cols-2 gap-6">
          <HomeInspirationItem
            item={home_inspiration_items[0]}
            imageWidth={636}
            imageHeigth={494}
            linkClassName={'col-span-1'}
            index={0}
          />
          <div className="col-span-1 grid grid-rows-2 gap-6">
            <HomeInspirationItem
              item={home_inspiration_items[1]}
              imageWidth={636}
              imageHeigth={208}
              index={1}
            />
            <HomeInspirationItem
              item={home_inspiration_items[2]}
              imageWidth={636}
              imageHeigth={208}
              index={2}
            />
          </div>
        </div>

        <SwiperReact
          className="mt-6"
          spaceBetween={24}
          breakpoints={{
            768: {
              slidesPerView: 3
            },
            1024: {
              slidesPerView: 4
            }
          }}
        >
          {home_inspiration_items?.slice(3).map((item, index) => (
            <SwiperSlide key={index} className="flex-shrink-0">
              <HomeInspirationItem
                item={item}
                imageWidth={306}
                imageHeigth={282}
                descriptionClassName="mt-3 text-base font-medium"
                index={index + 3}
              />
            </SwiperSlide>
          ))}
        </SwiperReact>
      </div>
    </div>
  );
}
