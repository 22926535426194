'use client';

import { SwiperReact, SwiperSlide } from '@theme/components';
import { SlideshowWidgetType } from './type';
import { Image } from '@akinon/next/components/image';
import { Pagination } from 'swiper/modules';
import clsx from 'clsx';

export default function Slideshow({ data }: { data: SlideshowWidgetType }) {
  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return;

  const slides = attributes?.slideshow;

  if (!Array.isArray(slides) || !slides.length) return;

  return (
    <section
      className={clsx(
        'border-b border-b-lightBlue-500 py-10',
        'lg:pb-[72px] lg:pt-0'
      )}
      data-id="slide-show"
    >
      <div className="container lg:max-w-[1440px]">
        <SwiperReact
          className={clsx(
            'h-[485.4px]',
            'lg:h-full lg:max-h-[528px]',
            '[&_.swiper-pagination-bullets]:hidden lg:[&_.swiper-pagination-bullets]:mr-[72px] lg:[&_.swiper-pagination-bullets]:block'
          )}
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{
            bulletActiveClass:
              'swiper-pagination-bullet-active swiper-pagination-bullet-active !bg-white',
            bulletClass:
              'bullet-item cursor-pointer block bg-transparent border border-white opacity-100 w-3 h-3 rounded-full mb-2',
            clickable: true
          }}
        >
          {slides.map((slide, index) => {
            const desktop_image = slide?.kwargs?.value?.desktop_image?.url;
            const mobile_image = slide?.kwargs?.value?.mobile_image?.url;
            const content = slide?.value?.content;
            return (
              <SwiperSlide
                className={clsx('h-full', '')}
                key={`slideshow-slider-${index}`}
              >
                {desktop_image && (
                  <Image
                    className="hidden h-full w-full lg:block"
                    imageClassName="w-full h-full object-cover"
                    width={1440}
                    height={528}
                    src={desktop_image}
                    alt={content}
                  />
                )}
                {(mobile_image || desktop_image) && (
                  <Image
                    className="h-full w-full lg:hidden"
                    imageClassName="aspect-[3/4] w-full h-full object-cover"
                    width={343}
                    height={485}
                    src={mobile_image || desktop_image}
                    alt={content}
                  />
                )}
                {content && (
                  <div className="container absolute left-1/2 top-0 h-full w-full -translate-x-1/2  transform p-0">
                    <div
                      className={clsx(
                        'absolute top-1/2 box-content max-w-[255px] -translate-y-1/2 transform pl-4',
                        'lg:left-0 lg:top-[120px] lg:max-w-full lg:transform-none lg:pl-0'
                      )}
                    >
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: content
                        }}
                        className={clsx(
                          'text-[32px] leading-[1.38] text-white',
                          'lg:text-5xl lg:[&_strong]:font-semibold lg:[&_strong]:leading-[1.5] lg:[&_strong]:tracking-[-0.96px]'
                        )}
                      ></h4>
                    </div>
                  </div>
                )}
              </SwiperSlide>
            );
          })}
        </SwiperReact>
      </div>
    </section>
  );
}
