'use client';

import { Product } from '@akinon/next/types';
import { Button, Icon, SwiperReact, SwiperSlide } from '@theme/components';
import { ProductItem } from '@theme/views/product-item';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Swiper from 'swiper';
export function RecommendationWithBannerProducts({
  products
}: {
  products: Product[];
}) {
  const swiperRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  if (!Array.isArray(products) || !products.length) return;

  return (
    <SwiperReact
      ref={swiperRef}
      onSlideChange={(swiper: Swiper) => {
        setCurrentSlideIndex(swiper?.activeIndex);
      }}
      slidesPerView={1.915}
      className={clsx(
        'flex !overflow-visible',
        '[&:not(.swiper-initialized)_.swiper-slide]:mr-4 [&:not(.swiper-initialized)_.swiper-slide]:max-w-[200px]',
        '[&:not(.swiper-initialized)_.swiper-slide]lg:mr-6 [&:not(.swiper-initialized)_.swiper-slide]:lg:max-w-[306px]'
      )}
      spaceBetween={16}
      breakpoints={{ 1024: { slidesPerView: 4, spaceBetween: 24 } }}
    >
      {products?.map((product, index: number) => {
        return (
          <SwiperSlide
            className="flex-shrink-0 "
            key={`account-recommendation-product-${index}`}
          >
            <ProductItem
              className="[&_.h-11]:h-auto [&_.product-badge]:hidden"
              width={113}
              height={130}
              index={index}
              product={product}
            />
          </SwiperSlide>
        );
      })}
      <Button
        onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
        className={clsx(
          'grid !h-8  !min-h-8 !min-w-8 max-w-8 transform items-center rounded-full',
          'border-0 bg-gray-100 p-0 text-primary outline-0',
          'absolute left-4 top-1/2 z-30 translate-y-1/2'
        )}
      >
        <Icon className="justify-center" name="chevron-start" size={14} />
      </Button>
      <Button
        onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
        className={clsx(
          'grid !h-8 !min-h-8 !min-w-8 max-w-8 transform items-center rounded-full',
          'border-0 bg-gray-100 p-0 text-primary outline-0',
          'absolute right-4 top-1/2 z-30 translate-y-1/2 '
        )}
      >
        <Icon className="justify-center" name="chevron-end" size={14} />
      </Button>
    </SwiperReact>
  );
}
