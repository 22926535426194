'use client';

import { Button, Icon, Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import { SingleShoppableItemType } from './type';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

export default function SingleShoppableItem({
  data
}: {
  data: SingleShoppableItemType;
}) {
  const attributes = data?.attributes;
  const bannerImage =
    attributes?.shoppable_image?.kwargs?.value?.desktop_banner_image?.url;
  const mobileBannerImage =
    attributes?.shoppable_image?.kwargs?.value?.mobile_banner_image?.url;
  const hotspotItems = attributes?.shoppable_image_hotspot;
  const [activeHotspot, setActiveHotspot] = useState<string>(null);

  useEffect(() => {
    const closeActiveHotspot = (e) => {
      if (!e.target.closest('.hotspot-item') || e.key == 'Escape') {
        setActiveHotspot(null);
      }
    };
    document.addEventListener('mousedown', closeActiveHotspot);
    document.addEventListener('keydown', closeActiveHotspot);
    return () => {
      document.removeEventListener('mousedown', closeActiveHotspot);
      document.addEventListener('keydown', closeActiveHotspot);
    };
  }, [activeHotspot]);

  const hotspotContentLocations = {
    bottom: 'top-10 left-0 transform -translate-x-1/2',
    top: 'bottom-10 -left-12 left-0 transform -translate-x-1/2',
    right: 'top-0 left-10',
    left: 'top-0 right-10'
  };

  if (Object.keys(attributes || {}).length === 0) return;

  return (
    <section
      className="border-b border-lightBlue-500 pb-10 pt-4 lg:py-[72px] lg:pt-0"
      data-id="shoppable-slider"
    >
      <div className="container">
        <div className="relative">
          {bannerImage && (
            <>
              <Image
                className="shoppable-image-wrapper hidden h-full w-full lg:block"
                imageClassName="w-full h-full object-cover"
                src={bannerImage}
                alt="shoppable-slider-desktop-image"
                width={1296}
                height={600}
              />
              <Image
                className="shoppable-image-wrapper h-[244px]  w-full lg:hidden"
                imageClassName="w-full h-full object-cover"
                src={mobileBannerImage}
                alt="shoppable-slider-mobile-image"
                width={343}
                height={244}
              />
              <Button className="absolute bottom-4 right-4 hidden h-10 w-10 rounded-full p-2 lg:bottom-6 lg:right-6 lg:block lg:!h-12 lg:!w-12">
                <Icon
                  className="justify-center lg:!text-lg"
                  name="plus"
                  size={12}
                />
              </Button>
            </>
          )}
          {hotspotItems.length > 0 && (
            <div className="shoppable-slider-hotspots">
              {hotspotItems.map((hotspot, index) => {
                const position = {
                  x: hotspot?.value.pin_x_position,
                  y: hotspot?.value.pin_y_position,
                  x_mobile: hotspot?.value?.mobile_pin_x_position,
                  y_mobile: hotspot?.value?.mobile_pin_y_position
                };

                const product = {
                  name: hotspot?.value?.product_name,
                  link: hotspot?.value?.product_link,
                  price: hotspot?.value?.product_price
                };

                const productPrice = hotspot?.value?.product_price;
                const hotspotContentLocation =
                  hotspot.value.pin_content_location || 'top';

                return (
                  <div
                    style={
                      {
                        '--x': `${position?.x}%`,
                        '--y': `${position?.y}%`,
                        '--x-mobile': `${position?.x_mobile || position?.x}%`,
                        '--y-mobile': `${position?.y_mobile || position?.y}%`
                      } as React.CSSProperties
                    }
                    className={clsx(
                      'z-5 hotspot-item absolute flex flex-col gap-2',
                      'left-[var(--x-mobile)] top-[var(--y-mobile)]',
                      'lg:left-[var(--x)] lg:top-[var(--y)]',
                      {
                        'z-10': `${index}` === activeHotspot
                      }
                    )}
                    key={`shoppable-image-hotspot-${index}`}
                  >
                    <Link
                      target="_blank"
                      href={product.link}
                      className={clsx(
                        'absolute hidden w-max items-center gap-3 bg-primary p-2 text-white',

                        {
                          '!flex': `${index}` === activeHotspot
                        },
                        hotspotContentLocations[hotspotContentLocation]
                      )}
                    >
                      {product.name && (
                        <span className="text-xs underline underline-offset-2">
                          {product.name}
                        </span>
                      )}
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-bold">
                          {productPrice}
                        </span>
                        <Icon name="arrow-right" size={20} />
                      </div>
                    </Link>
                    <button
                      onClick={() => setActiveHotspot(`${index}`)}
                      style={{
                        boxShadow: '0 4px 24px -1px rgba(0, 0, 0, 0.2)'
                      }}
                      className="grid h-8 w-8 place-items-center  rounded-full bg-black bg-opacity-20 backdrop-blur-[40px]"
                    >
                      <span
                        className={clsx('block h-4 w-4 rounded-full bg-white', {
                          'h-6 w-6 !bg-primary': `${index}` === activeHotspot
                        })}
                      ></span>
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
