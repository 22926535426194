'use client';

import { FilterableItemSearchType } from './type';
import clsx from 'clsx';
import { Select, Icon, Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export default function DescriptionWithSlider({
  data
}: {
  data: FilterableItemSearchType;
}) {
  const { attributes } = data || {};
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const filteredEvents = attributes?.events?.filter((event) => {
    const matchSearch = event.value.title
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const matchStatus =
      filterStatus === '' ||
      filterStatus === 'all' ||
      event.value.status === filterStatus;
    return matchSearch && matchStatus;
  });

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const eventStatus = Array.isArray(attributes?.dropdown)
    ? attributes?.dropdown.map((item) => ({
        value: item.value.value,
        label: item.value.label
      }))
    : [];

  return (
    <div className="container">
      <div className="my-4 flex flex-row gap-6">
        <div className="relative flex h-10 w-full items-center gap-4 bg-gray-50 px-6 ring-primary lg:w-[500px] [&:has(:focus-visible)]:ring-1">
          <Icon className="text-gray-700" name="search" size={24} />
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={attributes?.search_text?.value}
            className="inset-0 h-full w-full border-0 bg-gray-50 bg-transparent outline-0 placeholder:text-ellipsis placeholder:text-gray-600"
          />
        </div>
        <div className="h-10 w-[1px] bg-lightBlue"></div>
        <div>
          <Select
            options={eventStatus}
            onChange={handleFilterChange}
            className="h-[40px] w-[160px] border border-gray-700 leading-[1.43] text-gray-700 lg:w-[190px] lg:max-w-[190px]"
          ></Select>
        </div>
      </div>
      <hr className="ml-[calc(calc(-100vw_-_1296px)_/_2)] w-[calc(calc(100vw_+_1296px))] border-lightBlue-500 lg:block" />

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {filteredEvents?.map((event, index) => {
          return (
            <div key={index} className={twMerge('flex flex-col pt-4')}>
              <Link href={event?.value?.url}>
                <Image
                  imageClassName="w-full"
                  className="mb-2 hidden object-cover object-center lg:block"
                  alt={event?.value?.alt}
                  src={event?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                  aspectRatio={1 / 1}
                  sizes="343px"
                  fill
                />
                <Image
                  imageClassName="w-full"
                  className="mb-2 object-cover object-center lg:hidden"
                  alt={event?.value?.alt}
                  src={
                    event?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg'
                  }
                  aspectRatio={416 / 416}
                  sizes="416px"
                  fill
                />
              </Link>

              {event?.value?.badge_text && (
                <span
                  className={clsx(
                    'mb-2 w-fit bg-secondary-100 px-2 py-1 text-xs leading-[1.5] text-secondary-700',
                    'lg:px-3 lg:py-[6px] lg:text-sm lg:leading-[1.43]'
                  )}
                >
                  {event?.value?.badge_text}
                </span>
              )}
              {event?.value?.title && (
                <Link
                  href={event?.value?.url}
                  className={clsx(
                    'mb-2 text-lg font-medium leading-[1.56]',
                    'lg:text-2xl lg:leading-[1.33]'
                  )}
                >
                  {event.value.title}
                </Link>
              )}
              {event?.value?.content && (
                <div
                  className={clsx(
                    'text-sm leading-[1.43]',
                    'lg:text-base lg:leading-[1.5]'
                  )}
                >
                  {event.value.content}
                </div>
              )}
              <div className="mt-auto">
                {index % 3 === 0 && (
                  <hr className="ml-[calc(calc(-100vw_-_1296px)_/_2)] mt-10 hidden w-[calc(calc(100vw_+_1296px))] border-lightBlue-500 lg:block" />
                )}
                <hr className="col-[1_/_4] ml-[-16px] mt-10 block h-px w-[calc(100%_+_32px)] border-lightBlue-500 lg:hidden" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
