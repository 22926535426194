'use client';
import { Link } from '@akinon/next/components/link';
import { Image } from '@akinon/next/components/image';
import { Button, SwiperReact, SwiperSlide } from '@theme/components';
import MiniProductCard from './mini-product-card';
import { useRef } from 'react';
import { SwiperRef } from 'swiper/react';
import { Icon } from '@akinon/next/components/icon';
import { WidgetData } from './type';

const GiftFourProductWithBanner = ({ data }: WidgetData) => {
  const swiperRef = useRef<SwiperRef>(null);

  if (!data) return null;

  return (
    <section className="border-b border-b-lightBlue py-10 md:py-[72px]">
      <div className="container">
        <h4 className="mx-auto mb-6 max-w-[695px] text-center text-2xl text-primary md:mb-12 md:text-[40px] md:leading-[52px] md:tracking-[-0.4px]">
          {data.attributes?.banner_with_four_products?.value.section_title}
        </h4>
        <div className="flex flex-col gap-4 md:flex-row md:gap-6">
          <Link
            href={
              data.attributes?.banner_with_four_products?.value?.banner_link ||
              '#'
            }
            className="relative max-h-[240px] md:max-h-[656px] md:basis-1/2"
          >
            <Image
              src={
                data.attributes?.banner_with_four_products?.kwargs?.value?.image
                  ?.url
              }
              alt="Gift Four Product With Banner"
              aspectRatio={636 / 656}
              sizes="(max-width: 768px) 100vw, 636px"
              fill
              className="hidden md:block md:max-h-[656px]"
            />
            <Image
              src={
                data.attributes?.banner_with_four_products?.kwargs?.value
                  ?.mobile_image?.url
              }
              alt="Gift Four Product With Banner"
              aspectRatio={608 / 245}
              sizes="(max-width: 768px) 100vw, 608px"
              fill
              className="max-h-[240px] md:hidden"
            />
            <div
              style={{
                color:
                  data.attributes?.banner_with_four_products?.value
                    ?.banner_text_color ?? '#ffffff'
              }}
              className="absolute left-8 top-4 md:top-8"
            >
              <p className=" mb-6 max-w-[470px] text-lg leading-7 text-white md:text-[2rem] md:leading-[44px] ">
                {data.attributes?.banner_with_four_products?.value?.banner_text}
              </p>
              <Button
                style={{
                  backgroundColor:
                    data.attributes?.banner_with_four_products?.value
                      ?.banner_button_bg_color ?? '#ffffff',
                  color:
                    data.attributes?.banner_with_four_products?.value
                      ?.banner_button_text_color ?? '#1a1a1a'
                }}
                appearance="outlined"
                className="h-auto p-1.5 lg:px-[15px] lg:py-[11px] lg:h-auto text-sm lg:text-base"
              >
                {
                  data.attributes?.banner_with_four_products?.value
                    ?.banner_button_text
                }
              </Button>
            </div>
          </Link>
          <div className="hidden gap-4 md:flex md:basis-1/2 md:flex-wrap md:gap-6">
            {data.products &&
              data.products.length > 0 &&
              data.products?.map((product, index) => {
                if (index === 0) {
                  return (
                    <MiniProductCard
                      key={index}
                      product={product}
                      className="mb-2 basis-[179px] md:basis-[306px]"
                    />
                  );
                }
                return (
                  <MiniProductCard
                    key={index}
                    product={product}
                    className="basis-[179px] md:basis-[306px]"
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className="mt-[9px] pl-4 md:hidden">
        <SwiperReact
          loop={true}
          className="!relative"
          ref={swiperRef}
          slidesPerView={2}
          spaceBetween={16}
        >
          {data.products &&
            data.products.length > 0 &&
            data.products?.map((product, index) => {
              return (
                <SwiperSlide className="!min-w-[179px]" key={index}>
                  <MiniProductCard
                    key={index}
                    product={product}
                    className="min-w-full"
                  />
                </SwiperSlide>
              );
            })}
          <button
            className="absolute right-4 top-1/2 z-10 flex -translate-y-1/2 items-center justify-center rounded-full bg-gray-100 p-1"
            onClick={() => {
              swiperRef.current?.swiper.slideNext();
            }}
          >
            <Icon name="right" size={24} />
          </button>
          <button
            className="absolute left-0 top-1/2 z-10 flex -translate-y-1/2 items-center justify-center rounded-full bg-gray-100 p-1"
            onClick={() => {
              swiperRef.current?.swiper.slidePrev();
            }}
          >
            <Icon name="left" size={24} />
          </button>
        </SwiperReact>
      </div>
    </section>
  );
};

export default GiftFourProductWithBanner;
