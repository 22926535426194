'use client';

import clsx from 'clsx';
import { Image } from '@akinon/next/components/image';
import { ImageBannerWithTextAndButtonType } from './type';
import React from 'react';
import { Button, Link } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';

export default function ImageWithOverlayBanner({
  data
}: {
  data: ImageBannerWithTextAndButtonType;
}) {
  const { t } = useLocalization();
  if (!data?.attributes) return null;
  const attr = data?.attributes?.banner;
  return (
    <div className="border-b border-lightBlue">
      <div className="container relative my-10 lg:my-16">
        <Image
          imageClassName="w-[343px] h-[250px]"
          className="block h-[250px] w-full object-cover object-center lg:hidden"
          alt={attr?.value?.alt}
          src={attr?.kwargs?.value?.mobile_image?.url}
          aspectRatio={343 / 250}
          sizes="343px"
          fill
        />

        <Image
          imageClassName="w-[1296px] h-[400px]"
          className="hidden h-[400px] w-full object-cover object-center lg:block"
          alt={attr?.value?.alt}
          src={attr?.kwargs?.value?.image?.url}
          aspectRatio={1296 / 400}
          sizes="1296px"
          fill
        />
        <p
          className={clsx(
            'absolute left-4 top-0 mx-4 my-4 w-[276px] text-[28px] leading-[1.29] text-primary ',
            'lg:left-0 lg:mx-4 lg:mt-10 lg:w-[420px] lg:text-5xl lg:leading-[1.25]'
          )}
        >
          {attr?.value?.title}
        </p>
        <Link
          href={attr?.value?.url}
          className={clsx('absolute left-4 top-0 w-[inherit]', '')}
        >
          <Button
            appearance="filled"
            className={clsx(
              'absolute left-4 top-[104px] h-10 px-4 py-2 text-base leading-[1.5]',
              'lg:left-2 lg:top-[192px]'
            )}
          >
            {t('flat_pages.events.single_image_banner.button.text')}
          </Button>
        </Link>
      </div>
    </div>
  );
}
