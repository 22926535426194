'use client';
import { Button, Link, TabPanel, Tabs } from '@theme/components';
import clsx from 'clsx';
import { TabsWithContentType } from './type';
import { Image } from '@akinon/next/components/image';
import { useMediaQuery } from '@akinon/next/hooks';

export default function TabsWithContentWidget({
  data
}: {
  data: TabsWithContentType;
}) {
  const tabs = data?.attributes?.tabs;
  const isMobile = useMediaQuery('(max-width: 1024px)');

  if (!Array.isArray(tabs) || !tabs?.length) return null;

  return (
    <div
      className={clsx('border-b border-lightBlue-500 py-10', 'lg:py-[72px]')}
    >
      <div className="container">
        <Tabs
          className={clsx(
            'lg:flex lg:flex-row lg:[&>.tabs-wrapper]:mr-2 lg:[&_ul]:min-w-[156px] lg:[&_ul]:flex-col lg:[&_ul]:items-start',
            '2xl:[&>.tabs-wrapper]:mr-6 2xl:[&>.tabs-wrapper]:min-w-[196px]'
          )}
        >
          {tabs.map((tab, index) => {
            const tabTitle = tab?.value?.tab_title;
            const title = tab?.value?.title;
            const description = tab?.value?.description;
            const buttonLabel = tab?.value?.button_label;
            const buttonLink = tab?.value?.button_link;
            const image = tab?.kwargs?.value?.image?.url;
            const mobileImage = tab?.kwargs?.value?.mobile_image?.url;
            return (
              <TabPanel
                activeTabClassName="text-primary border-b border-primary font-bold lg:whitespace-nowrap"
                tabClassName={clsx(
                  'mb-2 bg-transparent px-0 text-sm normal-case text-primary',
                  'lg:mb-0 lg:w-full lg:justify-start lg:px-4 lg:py-2 lg:text-base'
                )}
                key={index}
                title={tabTitle}
              >
                <div className={clsx('flex flex-col gap-6', 'lg:flex-row')}>
                  <div>
                    {title && (
                      <h4
                        className={clsx(
                          'mb-2 text-xl font-medium leading-[1.5]',
                          'lg:mb-4 lg:text-2xl lg:leading-[1.33]'
                        )}
                      >
                        {title}
                      </h4>
                    )}
                    {description && (
                      <div
                        className={clsx(
                          'mb-4 text-sm leading-[1.43]',
                          'lg:mb-8 lg:text-base lg:leading-[1.5]'
                        )}
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></div>
                    )}
                    {buttonLabel && (
                      <Link href={buttonLink}>
                        <Button appearance="outlined">{buttonLabel}</Button>
                      </Link>
                    )}
                  </div>
                  {image && (
                    <Image
                      aspectRatio={1.372}
                      fill
                      sizes="100vw"
                      src={isMobile ? mobileImage : image}
                      alt={title}
                      className={clsx(
                        'max-h-[250px]',
                        'lg:max-h-[440px] lg:w-[min(636px,45vw)] lg:flex-shrink-0'
                      )}
                      imageClassName="w-full h-full object-cover"
                    />
                  )}
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}
