'use client';
import {
  Button,
  Icon,
  Link,
  LoaderSpinner,
  SwiperReact,
  SwiperSlide,
  TabPanel,
  Tabs
} from '@theme/components';
import { TabbedCategoryAreaType } from './type';
import { useEffect, useRef, useState } from 'react';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';
import Swiper from 'swiper';
import { useMediaQuery } from '@akinon/next/hooks';

type SwiperStateType = {
  isEnd?: boolean;
  isBeginning?: boolean;
};

export default function ImageWithOverlayBanner({
  data
}: {
  data: TabbedCategoryAreaType;
}) {
  const tabs = data?.attributes.tabs;
  const isMobile = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      setSlug(tabs[0].value.slug_name);
    }
  }, [tabs]);

  const [slug, setSlug] = useState(null);
  const { data: slugData, isLoading, isSuccess } = useGetWidgetQuery(slug);
  const sData = slugData?.attributes ?? {};

  const items = sData?.slider_items;

  const swiperRef = useRef(null);

  const [swiperState, setSwiperState] = useState<SwiperStateType>({
    isBeginning: true
  });

  if (!Array.isArray(tabs) || !tabs.length) return;

  return (
    <div className="border-b border-lightBlue">
      <div className="container mb-10 mt-6 lg:mb-[72px] lg:mt-10">
        <Tabs>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                onClick={() => setSlug(tab?.value?.slug_name)}
                key={index}
                title={tab?.value.title}
              >
                {isLoading && (
                  <div className="mb-4 flex justify-center">
                    <LoaderSpinner />
                  </div>
                )}

                {isSuccess && (
                  <div>
                    <div className="bg-secondary-50">
                      <div className="relative lg:mr-6 lg:mt-8 ">
                        <Image
                          imageClassName="w-full h-[424px]"
                          className="block h-[424px] object-cover object-center lg:hidden"
                          alt={sData?.main?.value?.title}
                          src={sData?.main?.kwargs?.value?.mobile_image?.url}
                          aspectRatio={343 / 424}
                          sizes="343px"
                          fill
                        />

                        <Image
                          imageClassName="w-full h-[400px]"
                          className="hidden  object-cover object-center lg:block"
                          alt={sData?.main?.value?.title}
                          src={sData?.main?.kwargs?.value?.image?.url}
                          aspectRatio={1272 / 400}
                          sizes="1272px"
                          fill
                        />
                        <span className="absolute left-3 top-3 lg:left-6 lg:top-12">
                          <p className="text-[28px] leading-[1.29] text-primary lg:text-5xl lg:leading-[1.25]">
                            {sData?.main?.value?.title}
                          </p>
                          {sData?.main?.value?.button_text &&
                            sData?.main?.value?.url && (
                              <Link href={sData?.main?.value?.url}>
                                <Button
                                  appearance="filled"
                                  className="mt-4 h-10 px-4 py-2 leading-[1.5] lg:mt-8 lg:py-3"
                                >
                                  {sData?.main?.value?.button_text}
                                </Button>
                              </Link>
                            )}
                        </span>
                      </div>
                    </div>
                    <SwiperReact
                      loop
                      watchOverflow
                      ref={swiperRef}
                      onSlideChange={({ isEnd, isBeginning }: Swiper) => {
                        setSwiperState({ isEnd, isBeginning });
                      }}
                      className={clsx('mt-12')}
                      spaceBetween={24}
                      slidesPerView="auto"
                    >
                      {items?.map((item, index) => {
                        const title = item.value.title;
                        const image = item?.kwargs?.value?.image?.url;
                        const mobileImage =
                          item?.kwargs?.value?.mobile_image?.url;

                        return (
                          <SwiperSlide
                            className="!w-fit"
                            key={`carousel-content-slide-${index}`}
                          >
                            <div className="relative w-[200px] lg:w-[300px]">
                              <Image
                                imageClassName="w-[200px] lg:w-[300px] object-cover"
                                alt={title}
                                src={isMobile ? mobileImage : image}
                                fill
                                sizes="200px"
                                aspectRatio={1 / 1}
                              />

                              <h3
                                className={clsx(
                                  ' mt-[5px] text-center text-base font-medium leading-[1.5]',
                                  'lg:mt-[9px] lg:text-xl'
                                )}
                              >
                                {title}
                              </h3>

                              <div className="mt-3 lg:mt-4">
                                <Link
                                  className="flex items-center"
                                  href={item?.value?.url}
                                >
                                  <Button
                                    appearance="outlined"
                                    className="mx-auto !h-10 w-[120px] leading-[1.5]"
                                  >
                                    {item?.value?.button_text}
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                      <Button
                        className={clsx(
                          'absolute left-0 top-1/2 z-10 hidden !h-8 !w-8 -translate-y-1/2 transform rounded-full border-primary bg-primary p-1 text-white',
                          'lg:block',
                          { '!hidden': swiperState?.isBeginning }
                        )}
                        onClick={() => {
                          swiperRef?.current?.swiper.slidePrev();
                        }}
                      >
                        <Icon
                          className="justify-center text-white"
                          name="chevron-start"
                          size={14}
                        />
                      </Button>
                      <Button
                        className={clsx(
                          'absolute right-0 top-1/2 z-10 hidden !h-8 !w-8 -translate-y-1/2 transform rounded-full border-primary bg-primary p-1 text-white',
                          'lg:block',
                          { '!hidden': swiperState?.isEnd }
                        )}
                        onClick={() => {
                          swiperRef?.current?.swiper.slideNext();
                        }}
                      >
                        <Icon
                          className="justify-center text-white"
                          name="chevron-end"
                          size={14}
                        />
                      </Button>
                    </SwiperReact>
                  </div>
                )}
              </TabPanel>
            ))}
        </Tabs>
      </div>
    </div>
  );
}
