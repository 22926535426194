'use client';
import { ImageBannerWidgetType } from './type';
import clsx from 'clsx';
import { Image } from '@akinon/next/components/image';

export default function ImageBannerWidget({
  data
}: {
  data: ImageBannerWidgetType;
}) {
  const attributes = data.attributes;

  if (Object.keys(attributes).length === 0) return;

  const desktopImage = attributes?.image?.kwargs?.url;
  const mobileImage = attributes?.mobile_image?.kwargs?.url;
  const title = attributes?.title?.value;

  return (
    <div
      className={clsx(
        'w-full border-b border-lightBlue pb-10',
        'lg:px-0 lg:pb-[72px] '
      )}
    >
      <div className={clsx('container relative max-w-[1440px] ')}>
        <div className="relative">
          {desktopImage && (
            <Image
              className="hidden lg:block"
              imageClassName="h-auto w-full object-cover"
              alt={title}
              sizes="100vw"
              src={desktopImage}
              width={1440}
              height={875}
            />
          )}
          {(mobileImage || desktopImage) && (
            <Image
              className="block lg:hidden"
              imageClassName="h-auto w-full object-cover"
              alt={title}
              sizes="100vw"
              src={mobileImage || desktopImage}
              width={750}
              height={1334}
            />
          )}
          <div
            className={clsx(
              'absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform px-4 text-center text-white',
              'lg:left-[72px] lg:translate-x-0 lg:px-0 lg:text-left'
            )}
          >
            <div className={clsx('max-w-[933px]', 'lg:px-0')}>
              {title && (
                <h4
                  dangerouslySetInnerHTML={{ __html: title }}
                  className={clsx(
                    'text-3xl leading-[1.33] [&_em]:text-[22px] [&_em]:leading-[28px]',
                    'lg:inline lg:text-5xl lg:leading-[60px] lg:[&_em]:text-[40px]'
                  )}
                ></h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
