'use client';

import { Image } from '@akinon/next/components/image';
import {
  Button,
  Icon,
  Link,
  SwiperNavigation,
  SwiperPagination,
  SwiperReact,
  SwiperSlide
} from '@theme/components';
import { pushSelectPromotion, pushViewPromotion } from '@theme/utils/gtm';
import clsx from 'clsx';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const MultipleBannerItem = ({ item, index }) => {
  const { ref, inView } = useInView();
  const [viewed, setViewed] = useState(false);

  const promotionEvent = useMemo(
    () => ({
      creative_name: item?.kwargs?.value?.image?.url,
      creative_slot: `Multiple Banner ${index + 1}`,
      promotion_id: `multiple-banner_${index + 1}`,
      promotion_name: item?.value?.banner_title
    }),
    [item, index]
  );

  useEffect(() => {
    if (inView && !viewed) {
      setViewed(true);
      pushViewPromotion(promotionEvent);
    }
  }, [inView, viewed, promotionEvent]);

  return (
    <>
      <Link
        href={item?.value?.banner_url}
        onClick={() => pushSelectPromotion(promotionEvent)}
      >
        <div ref={ref}>
          <Image
            src={item?.kwargs?.value?.mobile_image?.url || '/noimage.jpg'}
            alt={item?.value?.image_alt}
            aspectRatio={260 / 300}
            sizes="100vw"
            fill
            className="block md:hidden"
          />

          <Image
            src={item?.kwargs?.value?.image?.url || '/noimage.jpg'}
            alt={item?.value?.alt}
            imageClassName="object-cover w-full max-w-[416px] max-h-[452px]"
            aspectRatio={416 / 452}
            sizes="416px"
            fill
            className="hidden md:block"
          />

          <div className="mt-6 flex flex-col items-center justify-center gap-y-6">
            <div
              {...(item?.value?.banner_title_color && {
                style: { color: item?.value?.banner_title_color }
              })}
              className="h-14 px-2.5 text-center text-base lg:text-xl"
            >
              {item?.value?.banner_title}
            </div>
            <Button
              {...(item?.value?.banner_button_text_color && {
                style: { color: item?.value?.banner_button_text_color }
              })}
              className="h-7 truncate px-2 text-sm lg:h-10 lg:text-base"
              appearance="outlined"
            >
              {item?.value?.banner_button_text}
            </Button>
          </div>
        </div>
      </Link>
    </>
  );
};

export default function MultipleBannerContent({ data }) {
  return (
    <div className={clsx('border-b border-lightBlue py-10', 'lg:py-[72px]')}>
      <div className="mx-auto max-w-[1440px]">
        <div className=" mb-6 flex flex-col items-center lg:mb-[3.375rem]">
          <Image
            src={data?.attributes?.title_image?.kwargs?.url ?? '/noimage.jpg'}
            alt={'Paşabahçe'}
            className="mb-5 h-10 max-w-[236px] lg:h-[64px] lg:max-w-[23.625rem]"
            sizes="23.625rem"
            width={378}
            height={64}
          />
          <Link href={data?.attributes?.button_url?.value}>
            <div className="flex items-center gap-2">
              <span
                {...(data?.attributes?.button_text_color?.value && {
                  style: { color: data?.attributes?.button_text_color?.value }
                })}
                className="border-b border-primary text-sm lg:text-base"
              >
                {data?.attributes?.button_text?.value}
              </span>
              <Icon
                name="arrow-right"
                className="hidden text-2xl sm:block"
                size={24}
              />
            </div>
          </Link>
        </div>

        <div className="w-full lg:flex lg:justify-between lg:gap-x-6 lg:pl-[4.5rem]">
          <div className="mb-2.5 w-full px-4 lg:mb-0 lg:min-w-[19.125rem] lg:max-w-[19.125rem] lg:px-0">
            <Link
              className="relative flex items-center justify-center"
              href={data?.attributes?.static_banner?.value?.banner_url}
            >
              <Image
                src={
                  data?.attributes?.static_banner?.kwargs?.value?.mobile_image
                    ?.url ?? '/noimage.jpg'
                }
                alt={
                  data?.attributes?.static_banner?.value?.image_alt ??
                  'multiple banner mobile image'
                }
                className="lg:hidden"
                sizes="21.4375rem"
                aspectRatio={343 / 235}
                fill
              />
              <Image
                src={
                  data?.attributes?.static_banner?.kwargs?.value?.image?.url ??
                  '/noimage.jpg'
                }
                alt={
                  data?.attributes?.static_banner?.value?.image_alt ??
                  'multiple banner desktop image'
                }
                className="hidden lg:block lg:max-w-[306px]"
                sizes="19.125rem"
                aspectRatio={306 / 600}
                fill
              />

              <div className="absolute flex flex-col items-center justify-center gap-y-4 lg:gap-y-8">
                <span
                  {...(data?.attributes?.static_banner?.value
                    ?.banner_desc_color && {
                    style: {
                      color:
                        data?.attributes?.static_banner?.value
                          ?.banner_desc_color
                    }
                  })}
                  className="px-2 text-center text-lg lg:px-0 lg:text-2xl"
                >
                  {data?.attributes?.static_banner?.value?.banner_desc}
                </span>
                <Button
                  {...(data?.attributes?.static_banner?.value
                    ?.banner_button_text_color && {
                    style: {
                      color:
                        data?.attributes?.static_banner?.value
                          ?.banner_button_text_color
                    }
                  })}
                  className="h-[33px] px-3"
                  appearance="filled"
                >
                  {data?.attributes?.static_banner?.value?.banner_button_text}
                </Button>
              </div>
            </Link>
          </div>
          <div className="min-w-0 ps-4 lg:ps-0">
            <SwiperReact
              style={
                {
                  '--swiper-navigation-color': '#fff',
                  '--swiper-pagination-color': '#fff'
                } as CSSProperties
              }
              slidesPerView={1.35}
              spaceBetween="16"
              loop={true}
              modules={[SwiperPagination, SwiperNavigation]}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 2.38,
                  spaceBetween: '24'
                }
              }}
              onSlideChange={(swiper) => {
                if (swiper.realIndex === 0) return;
                document
                  .querySelector('.swiper-button-prev')
                  .classList.remove('!hidden');
              }}
            >
              {data?.attributes?.banners?.map((item, i) => (
                <SwiperSlide key={i}>
                  <MultipleBannerItem item={item} index={i} />
                </SwiperSlide>
              ))}
              <div
                className={clsx(
                  `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                  `after:!text-[.75rem] after:text-primary after:content-['prev']`,
                  '!hidden lg:bg-primary lg:after:text-white'
                )}
              ></div>
              <div
                className={clsx(
                  `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                  `after:!text-[.75rem] after:text-primary after:content-['next']`,
                  'lg:bg-primary lg:after:text-white'
                )}
              ></div>
            </SwiperReact>
          </div>
        </div>
      </div>
    </div>
  );
}
