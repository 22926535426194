'use client';

import { Button, Input, Modal } from '@theme/components';
import {
  FormFieldWithRightPhotoType,
  GetOfferForCorporateSolutionsType
} from './type';
import { useLocalization } from '@akinon/next/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useSendContactMutation } from '@akinon/next/data/client/account';
import { Image } from '@akinon/next/components/image';
import TextArea from '@theme/components/text-area';
import { useState } from 'react';

const sendToCorporateFormSchema = (t) =>
  yup.object().shape({
    full_name: yup
      .string()
      .required(t('flat_pages.corporate.form.errors.required'))
      .min(2, t('flat_pages.corporate.form.errors.min', { min: 2 }))
      .max(50, t('flat_pages.corporate.form.errors.max', { max: 50 }))
      .matches(
        /^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]+$/,
        t('flat_pages.corporate.form.errors.alfabetic')
      ),
    company_name: yup
      .string()
      .required(t('flat_pages.corporate.form.errors.required')),
    email: yup
      .string()
      .email(t('flat_pages.corporate.form.errors.email'))
      .required(t('flat_pages.corporate.form.errors.required')),
    phone: yup
      .string()
      .transform((value: string) =>
        value.replace(/^\+9/, '').replace(/[()\s\-_]/g, '')
      )
      .length(11, t('auth.register.form.error.phone_length'))
      .required(t('auth.register.form.error.required')),
    message: yup
      .string()
      .required(t('account.address_book.form.error.required'))
      .min(10, t('account.address_book.form.error.line_min'))
      .max(255, t('account.address_book.form.error.line_max'))
  });

export default function FormFieldWithRightPhoto({
  data
}: {
  data: FormFieldWithRightPhotoType;
}) {
  const { t } = useLocalization();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<GetOfferForCorporateSolutionsType>({
    resolver: yupResolver(sendToCorporateFormSchema(t))
  });

  const [sendContact, { isSuccess: formSuccess }] = useSendContactMutation();
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const onSubmit: SubmitHandler<GetOfferForCorporateSolutionsType> = (data) => {
    const formData = new FormData();
    formData.append('full_name', data.full_name);
    formData.append('company_name', data.company_name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('message', data.message);
    formData.append('subject', '1');

    sendContact(formData)
      .then(() => {
        setSuccessModalOpen(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  if (!data?.attributes) return null;
  const attr = data?.attributes;
  return (
    <div className="border-b border-lightBlue bg-gray-100">
      <div className="container mt-10  lg:mt-[72px]">
        <div className="flex lg:py-10">
          <div className="w-full px-4 py-10 lg:w-3/5 lg:bg-white lg:p-10">
            {formSuccess && (
              <Modal
                portalId="form-success-modal"
                open={isSuccessModalOpen}
                title={t('flat_pages.corporate.modal.title')}
                setOpen={(state) => {
                  setSuccessModalOpen(state);
                }}
                titleClassName="text-2xl font-medium leading-8"
                className="bottom-0 top-auto w-full max-w-lg translate-y-0 p-6 md:bottom-auto md:top-1/2 md:-translate-y-1/2"
                classNameHeader="p-0 pb-4 border-b-0"
              >
                <div className="max-h-[85vh] overflow-y-auto pb-8">
                  <div>{t('flat_pages.corporate.modal.content')}</div>
                </div>
                <Button
                  onClick={() => setSuccessModalOpen(false)}
                  className="h-12 w-full px-4 py-3 font-medium"
                >
                  {t('flat_pages.corporate.modal.button')}
                </Button>
              </Modal>
            )}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex h-full flex-col gap-6"
            >
              <span className="text-lg font-medium leading-[1.56] text-primary lg:text-2xl lg:leading-[1.33]">
                {attr?.get_offer?.value?.title}
              </span>
              <div
                className="text-sm leading-[1.43] text-primary lg:text-base lg:leading-6"
                dangerouslySetInnerHTML={{
                  __html: attr?.get_offer?.value?.content
                }}
              ></div>

              <Input
                name="full_name"
                labelStyle="default"
                data-testid="corporate-full_name"
                label={t('flat_pages.corporate.form.full_name.label')}
                placeholder={t(
                  'flat_pages.corporate.form.full_name.placeholder'
                )}
                className="border-lightBlue-600 !text-base "
                inputClassName="!px-4 placeholder:!text-sm"
                labelClassName="text-gray !mb-[6px] text-sm font-medium leading-[1.43]"
                errorClassName="!mt-[6px]"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {...register('full_name')}
                error={errors.full_name}
              />
              <Input
                name="company_name"
                labelStyle="default"
                data-testid="corporate-company_name"
                label={t('flat_pages.corporate.form.company_name.label')}
                placeholder={t(
                  'flat_pages.corporate.form.company_name.placeholder'
                )}
                className="border-lightBlue-600 !text-base"
                inputClassName="!px-4 placeholder:!text-sm"
                labelClassName="text-gray !mb-[6px] text-sm font-medium leading-[1.43]"
                errorClassName="!mt-[6px]"
                {...register('company_name')}
                error={errors.company_name}
              />
              <div className="grid-col-1 grid items-start gap-6 lg:grid-cols-2 lg:gap-4">
                <Input
                  name="email"
                  labelStyle="default"
                  data-testid="corporate-email"
                  label={t('flat_pages.corporate.form.email.label')}
                  placeholder={t('flat_pages.corporate.form.email.placeholder')}
                  className="border-lightBlue-600 ps-4 !text-base"
                  errorClassName="!mt-[6px]"
                  labelClassName="text-gray !mb-[6px] text-sm font-medium leading-[1.43]"
                  inputClassName="placeholder:!text-sm"
                  {...register('email')}
                  error={errors.email}
                />
                <Input
                  name="phone"
                  labelStyle="default"
                  data-testid="corporate-phone"
                  label={t('flat_pages.corporate.form.phone.label')}
                  className="border-lightBlue-600 ps-4 !text-base"
                  errorClassName="!mt-[6px]"
                  labelClassName="text-gray !mb-[6px] text-sm font-medium leading-[1.43]"
                  inputClassName="placeholder:!text-sm"
                  format="0 (###) ### ## ##"
                  mask="_"
                  control={control}
                  {...register('phone')}
                  error={errors.phone}
                  allowEmptyFormatting={true}
                />
              </div>

              <TextArea
                {...register('message')}
                label={t('flat_pages.corporate.form.message.label')}
                labelClassName="text-sm font-medium leading-[1.43] text-gray"
                className="text-xs text-gray-700 placeholder:text-sm placeholder:text-gray-700"
                error={errors.message}
                rows={6}
                data-testid="address-form-address-field"
                placeholder={t('flat_pages.corporate.form.message.placeholder')}
              />

              <Button
                appearance="filled"
                type="submit"
                data-testid="corporate-submit"
                className="mt-auto"
              >
                {t('flat_pages.corporate.form.submit')}
              </Button>
            </form>
          </div>
          {attr?.get_offer?.kwargs?.value?.image?.url && (
            <div className="hidden aspect-[550/692] lg:block lg:w-2/5">
              <Image
                imageClassName="w-full h-full object-cover"
                className="!block h-full"
                alt={t('flat_pages.corporate.form.message.placeholder')}
                src={attr?.get_offer?.kwargs?.value?.image?.url}
                width={550}
                height={692}
                aspectRatio={550 / 692}
              />
            </div>
          )}
        </div>
      </div>
      <div className="h-[72px] bg-white"></div>
    </div>
  );
}
