'use client';
import { Image } from '@akinon/next/components/image';
import {
  Button,
  Link,
  SwiperNavigation,
  SwiperReact,
  SwiperSlide
} from '@theme/components';
import clsx from 'clsx';
import { DesignerItem } from './type';

export default function TripleDesignersItems({
  data
}: {
  data: DesignerItem[];
}) {
  return (
    <SwiperReact
      slidesPerView={'auto'}
      spaceBetween={16}
      wrapperClass="!box-border"
      modules={[SwiperNavigation]}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }}
      onSlideChange={(swiper) => {
        if (swiper.realIndex === 0) return;
        document
          .querySelector('.swiper-button-prev')
          .classList.remove('!hidden');
      }}
      breakpoints={{
        768: {
          slidesPerView: 3,
          spaceBetween: 21
        }
      }}
      className="!overflow-visible !px-4 lg:container lg:!px-0 "
    >
      {data.map((designer, index) => {
        const designerInfo = {
          profileImage: designer?.kwargs?.value?.profile_image?.url,
          name: designer?.value?.name,
          nameColor: designer?.value?.name_color,
          country: designer?.value?.country,
          countryColor: designer?.value?.country_color,
          designCount: designer?.value?.design_count
        };

        const images = Array.from({ length: 4 }, (_, i) => i + 1).map((i) => {
          return designer?.kwargs?.value[`image_${i}`]?.url;
        });

        const mobileImages = Array.from({ length: 4 }, (_, i) => i + 1).map(
          (i) => {
            return designer?.kwargs?.value[`mobile_image_${i}`]?.url;
          }
        );

        const button = {
          label: designer?.value?.button_label,
          labelColor: designer?.value?.button_label_color,
          mobile_label: designer?.value?.mobile_button_label,
          url: designer?.value?.button_url
        };

        return (
          <SwiperSlide
            key={`triple-designer-${index}`}
            className="border border-lightBlue-500 mobile:!w-max"
          >
            <div className="flex flex-col gap-6 p-6">
              <div className="flex gap-3">
                {designerInfo.profileImage && (
                  <Image
                    width={40}
                    height={40}
                    className="h-10 w-10"
                    imageClassName="object-cover rounded-full w-full h-full"
                    alt={designerInfo?.name}
                    src={designerInfo.profileImage}
                  />
                )}
                <div className=" flex flex-col">
                  {designerInfo.name && (
                    <h5
                      {...(designerInfo.nameColor && {
                        style: { color: designerInfo.nameColor }
                      })}
                      className="text-base font-medium"
                    >
                      {designerInfo.name}
                    </h5>
                  )}
                  {designerInfo.country && (
                    <p
                      {...(designerInfo.countryColor && {
                        style: { color: designerInfo.countryColor }
                      })}
                      className="text-sm text-gray-600"
                    >
                      {designerInfo.country}
                    </p>
                  )}
                </div>
                {designerInfo.designCount && (
                  <span className="ml-auto text-sm  text-gray-600">
                    {designerInfo.designCount}
                  </span>
                )}
              </div>
              <div className="grid grid-cols-1 justify-center gap-3 lg:grid-cols-2">
                {images.map((image, index) => {
                  if (!image) return null;
                  return (
                    <Image
                      width={149}
                      height={149}
                      alt={designerInfo?.name}
                      className={clsx(
                        'hidden h-[165px] w-full max-w-[178px] lg:block'
                      )}
                      imageClassName="w-full h-full object-cover"
                      key={`desinger-product-${index}`}
                      src={image}
                    />
                  );
                })}

                {mobileImages.map((mobilImage, index) => {
                  if (!mobilImage) return null;
                  return (
                    <Image
                      width={149}
                      height={149}
                      alt={designerInfo?.name}
                      className={clsx(
                        'h-[165px] w-full max-w-[164px] md:max-w-full lg:hidden'
                      )}
                      imageClassName="w-full h-full object-cover"
                      key={`desinger-product-${index}`}
                      src={mobilImage}
                    />
                  );
                })}
              </div>
              {button.label && button.url && (
                <Link
                  className="ma-w-auto mx-auto lg:w-full lg:max-w-[273px]"
                  href={button.url}
                >
                  {(button.mobile_label || button.label) && (
                    <Button
                      {...(button.labelColor && {
                        style: { color: button.labelColor }
                      })}
                      className="!h-10 w-full px-3 text-sm lg:hidden"
                      appearance="outlined"
                    >
                      {button.mobile_label ?? button.label}
                    </Button>
                  )}

                  <Button
                    {...(button.labelColor && {
                      style: { color: button.labelColor }
                    })}
                    className="hidden !h-10 w-full px-0 text-base lg:block"
                    appearance="outlined"
                  >
                    {button.label}
                  </Button>
                </Link>
              )}
            </div>
          </SwiperSlide>
        );
      })}
      <div
        className={clsx(
          `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
          `after:!text-[.75rem] after:text-primary after:content-['prev']`,
          'lg:bg-primary lg:after:text-white'
        )}
      ></div>
      <div
        className={clsx(
          `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
          `after:!text-[.75rem] after:text-primary after:content-['next']`,
          'lg:bg-primary lg:after:text-white'
        )}
      ></div>
    </SwiperReact>
  );
}
