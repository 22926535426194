'use client';

import { NewsItemSearchType } from './type';
import clsx from 'clsx';
import { Icon, Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export default function NewsFlatpage({ data }: { data: NewsItemSearchType }) {
  const { attributes } = data || {};
  const [searchText, setSearchText] = useState('');

  const filteredEvents = attributes?.events?.filter((event) => {
    if (event?.value?.title) {
      const matchSearch = event.value.title
        .toLowerCase()
        .includes(searchText.toLowerCase());
      return matchSearch;
    }
  });

  return (
    <div className="container mb-10">
      <hr className="ml-[calc(calc(-100vw_-_1296px)_/_2)] w-[calc(calc(100vw_+_1296px))] border-lightBlue-500 lg:block" />

      <div className="my-4 flex flex-row gap-6">
        <div className="relative flex h-10 w-full items-center gap-4 bg-gray-50 px-6 ring-primary lg:w-[500px] [&:has(:focus-visible)]:ring-1">
          <Icon className="text-gray-700" name="search" size={24} />
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={attributes?.search_text?.value}
            className="inset-0 h-full w-full border-0 bg-gray-50 bg-transparent outline-0 placeholder:text-ellipsis placeholder:text-gray-600"
          />
        </div>
      </div>
      <hr className="ml-[calc(calc(-100vw_-_1296px)_/_2)] w-[calc(calc(100vw_+_1296px))] border-lightBlue-500 lg:block" />

      <div className="">
        {filteredEvents?.map((event, index) => {
          return (
            <div key={index} className={twMerge('flex flex-col pt-10')}>
              {index !== 0 && (
                <>
                  <hr className="mb-10 ml-[calc(calc(-100vw_-_1296px)_/_2)] hidden w-[calc(calc(100vw_+_1296px))] border-lightBlue-500 lg:block" />

                  <hr className="mb-10 border-lightBlue-500 lg:hidden" />
                </>
              )}
              <Link href={event?.value?.url}>
                <Image
                  imageClassName="w-[343px] h-[250px]"
                  className="block h-[250px] w-full object-cover object-center lg:hidden"
                  alt={event?.value?.alt}
                  src={
                    event?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg'
                  }
                  aspectRatio={343 / 250}
                  sizes="343px"
                  fill
                />

                <Image
                  imageClassName="w-[1296px] h-[400px]"
                  className="hidden h-[400px] w-full object-cover object-center lg:block"
                  alt={event?.value?.alt}
                  src={event?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                  aspectRatio={1296 / 400}
                  sizes="1296px"
                  fill
                />
              </Link>

              {event?.value?.badge_text && (
                <span
                  className={clsx(
                    'my-2 w-fit bg-secondary-100 px-2 py-1 text-xs leading-[1.5] text-secondary-700',
                    'lg:px-3 lg:py-[6px] lg:text-sm lg:leading-[1.43]'
                  )}
                >
                  {event?.value?.badge_text}
                </span>
              )}
              {event?.value?.title && (
                <Link
                  href={event?.value?.url}
                  className={clsx(
                    'mb-2 text-lg font-medium leading-[1.56]',
                    'lg:text-2xl lg:leading-[1.33]'
                  )}
                >
                  {event.value.title}
                </Link>
              )}
              {event?.value?.content && (
                <div
                  className={clsx(
                    'text-sm leading-[1.43]',
                    'lg:text-base lg:leading-[1.5]'
                  )}
                  dangerouslySetInnerHTML={{
                    __html: event.value.content
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
