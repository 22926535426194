'use client';
import { Link } from '@akinon/next/components/link';
import { Icon } from '@akinon/next/components/icon';
import { Skeleton } from '@theme/components';

const ContactUsPageTwoColumnsWithoutImages = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <section className="pt-10 md:mb-[72px] md:border-y md:border-b-lightBlue md:py-[71px]">
        <div className="container">
          <Skeleton
            type="title"
            className="mx-auto mb-10 block h-[44px] w-full lg:ml-0 lg:w-60"
          />
          <div className="flex flex-col items-center gap-4 md:flex-row md:gap-6">
            <Skeleton className="h-[172px] w-full lg:h-[256px]" />
            <Skeleton className="h-[172px] w-full lg:h-[256px]" />
          </div>
        </div>
      </section>
    );
  }

  const attributes = data?.attributes;
  if (Object.keys(attributes || {}).length === 0) return null;

  const sectionTitle = attributes?.section_title?.value?.title;
  const firstColumnTitle = attributes?.first_column?.value?.title;
  const firstColumnDescription = attributes?.first_column?.value?.description;
  const secondColumnTitle = attributes?.second_column?.value?.title;
  const secondColumnDescription = attributes?.second_column?.value?.description;

  return (
    <section className="pt-10 md:mb-[72px] md:border-y md:border-b-lightBlue md:py-[71px]">
      <div className="container">
        {sectionTitle && (
          <h3 className="mb-6 px-4 text-center text-2xl md:mb-10 md:px-0 md:text-left md:text-[2rem] md:leading-[44px]">
            {data.attributes?.section_title?.value?.title}
          </h3>
        )}
        <div className="flex flex-col items-center gap-4 md:flex-row md:gap-6">
          <Link
            href={'/sss'}
            className="inline-block w-full basis-1/2 border border-lightBlue px-4 pb-4 pt-6 md:py-10 md:pl-10 md:pr-6"
          >
            <div>
              {firstColumnTitle && (
                <p className="mb-3 text-xl font-medium leading-[30px] text-black md:mb-6 md:text-[1.75rem]">
                  {data.attributes?.first_column?.value?.title}
                </p>
              )}
              {firstColumnDescription && (
                <p className="mb-4 text-sm text-primary md:mb-6 md:text-lg">
                  {data.attributes?.first_column?.value?.description}
                </p>
              )}
              <div className="ml-auto flex h-8 w-8 items-center justify-center rounded-full border border-primary bg-white md:h-10 md:w-10">
                <Icon name="arrow-right" size={32} />
              </div>
            </div>
          </Link>
          <div
            onClick={() => {
              window?.EKOCCSChat?.toggleChatBox?.();
            }}
            className="inline-block w-full basis-1/2 cursor-pointer border border-lightBlue px-4 pb-4 pt-6 md:py-10 md:pl-10 md:pr-6"
          >
            <div>
              {secondColumnTitle && (
                <p className="mb-3 text-xl font-medium leading-[30px] text-black md:mb-6 md:text-[1.75rem]">
                  {data.attributes?.second_column?.value?.title}
                </p>
              )}
              {secondColumnDescription && (
                <p className="mb-4 text-sm text-primary md:mb-6 md:text-lg">
                  {data.attributes?.second_column?.value?.description}
                </p>
              )}
              <div className="ml-auto flex h-8 w-8 items-center justify-center rounded-full border border-primary bg-white md:h-10 md:w-10">
                <Icon name="arrow-right" size={32} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsPageTwoColumnsWithoutImages;
