'use client';

import { useState } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export default function CategorySelector({ onBrandChange, categories }) {
  const [selectedCategory, setSelectedCategory] = useState(categories[0].value.title);

  const categoryClickHandler = (title:string, slug: string) => {
    setSelectedCategory(title);
    onBrandChange(slug);
  };

  return (
    <div 
      style={{ WebkitOverflowScrolling: 'touch' }}
      className="flex items-center mb-6 md:mb-8 w-full snap-x snap-proximity overflow-x-auto"
    >
     {categories?.map((category, index) => {
      const { title, slug } = category.value;

      return (
        <button
            onClick={() => categoryClickHandler(title, slug)}
            key={index}
            className={twMerge(
              clsx(
                'snap-start',
                'text-sm md:text-lg',
                'p-2',
                'leading-5 md:leading-7',
                'text-gray-500',
                'font-medium',
                'transition-colors',
                'border-b-[0.125rem]',
                'border-transparent',
                'relative',
                'transition-all',
                'select-none',
                'shrink-0',
                selectedCategory === title &&
                  'border-b-primary text-primary pointer-events-none',
                  selectedCategory !== title && 'hover:text-primary'
              )
            )}
          >
            {title}
          </button>
      );
     })}
    </div>
  )
}
