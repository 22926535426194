'use client';
import { Image } from '@akinon/next/components/image';
import { EventItemCardSliderType } from './type';
import {
  Link,
  SwiperNavigation,
  SwiperReact,
  SwiperSlide
} from '@theme/components';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import { useLocalization, useMediaQuery } from '@akinon/next/hooks';

export default function EventItemCardSlider({
  data
}: {
  data: EventItemCardSliderType;
}) {
  const blogPosts = data?.attributes?.blog_posts;
  const { t } = useLocalization();
  const swiperRef = useRef(null);

  const isMobile = useMediaQuery('(max-width: 1024px)');
  const truncateText = (text, limit) => {
    if (text.length <= limit) return { truncated: text, isTruncated: false };
    const truncated = text.slice(0, limit) + '...';
    return { truncated, isTruncated: true };
  };

  const WORD_LIMIT = 300;
  const [expandedIndices, setExpandedIndices] = useState(
    blogPosts.map(() => false)
  );

  const handleToggleExpand = (index) => {
    setExpandedIndices((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  if (!blogPosts || blogPosts.length === 0) return null;
  const template = data?.template;
  return (
    <div className="border-b border-lightBlue">
      {template.includes('recommendation') && (
        <h2 className="leading[30px] lg:leading[44px] container text-left text-xl lg:text-center lg:text-[32px]">
          {t('flat_pages.events.blog_posts_detail.title')}
        </h2>
      )}
      <div className="container">
        <SwiperReact
          watchOverflow
          ref={swiperRef}
          modules={[SwiperNavigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          className={clsx('my-10 flex')}
          spaceBetween={0}
          slidesPerView="auto"
          breakpoints={{
            390: {
              spaceBetween: 16
            }
          }}
        >
          {blogPosts.map((item, index) => {
            const image = item?.kwargs?.value?.image?.url;
            const mobile_image = item?.kwargs?.value?.mobile_image?.url;
            const badge = item?.value?.badge;
            const title = item?.value?.name;
            const description = item?.value?.description;
            const link = item?.value?.link;

            const truncated = truncateText(description, WORD_LIMIT);
            const isExpanded = expandedIndices[index];

            return (
              <SwiperSlide
                className="!w-fit flex-shrink-0 last:pr-0 lg:pl-0 lg:pr-0"
                key={`carousel-content-slide-${index}`}
              >
                <div className="relative">
                  <div className="h-full w-[343px] lg:w-[416px]">
                    {image && (
                      <Link href={link || '#'}>
                        <Image
                          alt="related-event-image"
                          src={isMobile ? mobile_image : image}
                          width={343}
                          height={343}
                          className="mb-2 aspect-square h-full w-full  max-w-[343px] leading-[0] lg:max-w-[416px] "
                          imageClassName="object-cover w-full lg:max-w-[416px] max-w-[343px]"
                        />
                      </Link>
                    )}
                  </div>
                  <>
                    {badge && (
                      <div className="mb-2 w-fit self-start bg-secondary-100 px-2 py-1 text-xs text-secondary-700 lg:px-3 lg:py-1.5 lg:text-sm">
                        {badge}
                      </div>
                    )}
                    {title && (
                      <Link href={link || '#'}>
                        <h4 className="mb-1 text-lg font-medium lg:text-2xl">
                          {title}
                        </h4>
                      </Link>
                    )}
                    {description && (
                      <div className="h-full w-full  max-w-[343px] text-wrap text-sm lg:max-w-[416px] lg:text-base">
                        <div
                          className="pr-2"
                          dangerouslySetInnerHTML={{
                            __html: isExpanded
                              ? description
                              : truncated.truncated
                          }}
                        ></div>
                        {truncated.isTruncated && (
                          <button
                            onClick={() => handleToggleExpand(index)}
                            className="mt-2 text-primary-500"
                          >
                            <span className="font-bold ">
                              {isExpanded
                                ? t(
                                    'flat_pages.events.blog_posts.description.show_less'
                                  )
                                : t(
                                    'flat_pages.events.blog_posts.description.show_more'
                                  )}
                            </span>
                          </button>
                        )}
                      </div>
                    )}
                  </>
                </div>
              </SwiperSlide>
            );
          })}
          <div
            className={clsx(
              `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['prev']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
          <div
            className={clsx(
              `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['next']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
        </SwiperReact>
      </div>
    </div>
  );
}
