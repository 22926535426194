'use client';

import { useState } from 'react';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import CategorySelector from '@theme/views/widgets/category-selector';
import { SwiperNavigation, SwiperReact, SwiperSlide } from '@theme/components';
import { ProductItem } from '@theme/views/product-item';
import clsx from 'clsx';

export default function HomeRecommendationByCategories({ data: widgetData }) {
  const categories = widgetData?.attributes?.categories;

  const [selectedCategorySlug, setSelectedCategorySlug] = useState(
    categories[0]?.value?.slug
  );

  const { data, isFetching, isError } = useGetWidgetQuery(selectedCategorySlug);

  const categoryChangeHandler = (slug: string) => {
    setSelectedCategorySlug(slug);
  };

  if (!widgetData || !widgetData?.attributes?.categories) return null;

  return (
    <div className="border-t border-lightBlue py-[40px] md:py-[72px]">
      <div className="container px-4 ">
        {widgetData?.attributes?.title?.value && (
          <div
            {...(widgetData?.attributes?.title_color?.value && {
              style: { color: widgetData?.attributes?.title_color?.value }
            })}
            className="mb-6 flex justify-center px-5 text-center text-2xl leading-[32px] tracking-[-0.4px] md:mb-[54px] md:text-[36px] md:leading-[52px] lg:px-40"
          >
            {widgetData?.attributes?.title?.value}
          </div>
        )}
        <CategorySelector
          categories={categories}
          onBrandChange={categoryChangeHandler}
        />
        {!isFetching && !isError && (
          <div className="pb-4 pl-4 lg:pl-0">
            <div className="relative flex items-center">
              <div className="w-full overflow-hidden">
                <SwiperReact
                  slidesPerView={2}
                  className="w-full"
                  spaceBetween={16}
                  modules={[SwiperNavigation]}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 13.3
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 37
                    },
                    1440: {
                      slidesPerView: 4,
                      spaceBetween: 13.3
                    }
                  }}
                >
                  {data?.products &&
                    data?.products.length > 0 &&
                    data?.products?.map((product, index) => (
                      <SwiperSlide key={product.pk}>
                        <ProductItem
                          product={product}
                          key={product.pk}
                          width={306}
                          height={450}
                          index={index}
                        />
                      </SwiperSlide>
                    ))}
                  <div
                    className={clsx(
                      `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                      `after:!text-[.75rem] after:text-primary after:content-['prev']`,
                      'lg:bg-primary lg:after:text-white'
                    )}
                  ></div>
                  <div
                    className={clsx(
                      `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                      `after:!text-[.75rem] after:text-primary after:content-['next']`,
                      'lg:bg-primary lg:after:text-white'
                    )}
                  ></div>
                </SwiperReact>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
