'use client';

import { Image } from '@akinon/next/components/image';
import { Button, Link } from '@theme/components';
import clsx from 'clsx';

export default function IndexBannerContent({ data }) {
  const layout = data?.attributes?.index_banner.length;

  return (
    <div
      className={clsx('index-banner', 'grid grid-cols-2 gap-[5px]', {
        'md:grid-cols-3': layout > 2
      })}
    >
      {data?.attributes?.index_banner?.map((item, i) => (
        <div
          key={i}
          className={clsx('relative flex w-full', {
            'col-span-2 md:col-span-1': i === 2
          })}
        >
          <Link
            href={item?.value?.button_url}
            data-text={item?.value?.button_text}
            className="max-h-[489px] w-full md:max-h-full"
          >
            <Image
              src={item?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg'}
              alt={item?.value?.button_text}
              fill
              aspectRatio={i < 2 ? 185 / 489 : 375 / 185}
              sizes="100vw"
              className={clsx('h-full', 'w-full', 'object-cover md:hidden')}
            />
            <Image
              src={item?.kwargs?.value?.desktop_image?.url ?? '/noimage.jpg'}
              alt={item?.value?.button_text}
              fill
              aspectRatio={layout > 2 ? 470 / 676 : 707 / 676}
              sizes="100vw"
              className={clsx(
                'h-full',
                'w-full',
                'hidden object-cover md:block'
              )}
            />
          </Link>
          <div
            className={clsx(
              'absolute',
              'left-1/2',
              'bottom-[15px] md:bottom-[51px]',
              '-translate-x-1/2',
              'flex flex-col items-center text-center',
              'w-full'
            )}
          >
            <Link
              href={item.value?.button_url}
              data-text={item?.value?.button_text}
            >
              <Button
                appearance={'ghost'}
                className={clsx('h-auto text-[30px] text-black md:text-[35px]')}
              >
                {item.value?.button_text}
              </Button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
