'use client';

import { Image } from '@akinon/next/components/image';
import { Link } from '@theme/components';
import { SwiperReact, SwiperSlide, SwiperNavigation } from '@theme/components';
import { SpecialProjectsType } from './type';
import clsx from 'clsx';
import { useMediaQuery } from '@akinon/next/hooks';

export default function SpecialProjects({
  data
}: {
  data: SpecialProjectsType;
}) {
  const attributes = data?.attributes;
  const isMobile = useMediaQuery('(max-width: 1024px)');

  if (Object.keys(attributes || {}).length == 0) return;

  const title = data?.attributes?.title?.value;
  const bgColor = data?.attributes?.bg_color?.value;
  const textColor = data?.attributes?.text_color?.value;
  const description = data?.attributes?.page_description?.value;
  const SpecialProjectsImages = data?.attributes?.special_projects;

  return (
    <section className="mb-10 lg:mb-[72px]" data-id="special-projects">
      <div className="border-b border-lightBlue-500">
        <div className="px-4 lg:px-0">
          <div
            className="container mb-6 p-4 text-sm lg:mb-10 lg:p-6 lg:text-base"
            style={{ backgroundColor: bgColor, color: textColor }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        </div>
      </div>
      <div className="container mt-6 lg:mt-10">
        {title && (
          <h2 className="mb-6 text-center text-[24px] leading-[32px] lg:mb-12 lg:text-[40px] lg:leading-[52px] lg:tracking-[-0.4px]">
            {title}
          </h2>
        )}
        <div className="relative">
          <SwiperReact
            slidesPerView={1.62}
            spaceBetween={24}
            className="w-full !overflow-visible"
            modules={[SwiperNavigation]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
            breakpoints={{
              768: {
                slidesPerView: 3.07
              },
              1440: {
                slidesPerView: 4.07
              }
            }}
            onSlideChange={(swiper) => {
              if (swiper.realIndex === 0) {
                document
                  .querySelector('.swiper-button-prev')
                  .classList.add('!hidden');
              } else {
                document
                  .querySelector('.swiper-button-prev')
                  .classList.remove('!hidden');
              }
            }}
          >
            {SpecialProjectsImages &&
              SpecialProjectsImages.map((image, index) => {
                let mobileImage = image?.kwargs?.value?.mobile_image?.url;
                const desktopImage = image?.kwargs?.value?.image?.url;
                mobileImage ??= desktopImage;

                return (
                  <SwiperSlide key={index}>
                    <Link
                      href={image?.value?.url}
                      className="mb-4 flex flex-col items-center"
                    >
                      <Image
                        src={isMobile ? mobileImage : desktopImage}
                        alt={image?.value?.alt}
                        aspectRatio={1}
                        sizes="300px"
                        className="w-[200px] md:w-[300px]"
                        fill
                      />
                    </Link>
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-center text-base font-medium lg:text-xl">
                      {image?.value?.title}
                    </div>
                  </SwiperSlide>
                );
              })}
          </SwiperReact>
          <div
            className={clsx(
              `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['prev']`,
              '!hidden md:bg-primary md:after:text-white'
            )}
          ></div>
          <div
            className={clsx(
              `swiper-button-next swiper-button-next::after !mr-[0] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['next']`,
              '!hidden md:!right-0 md:!flex md:bg-primary md:after:text-white'
            )}
          ></div>
        </div>
      </div>
    </section>
  );
}
