'use client';

import { useLocalization } from '@akinon/next/hooks';
import { RetailCities, StoreResult } from './type';
import { StoresMap } from './map';
import StoresList from './list';
import { useState } from 'react';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';

interface Store {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: StoreResult[];
}

export default function StoresContent({
  stores,
  cities
}: {
  stores: Store;
  cities: RetailCities;
}) {
  const { t } = useLocalization();
  const [selectedStorePk, setSelectedStorePk] = useState<string | null>(null);
  const [selectedCityPk, setSelectedCityPk] = useState<string | null>(null);
  const [userLocation, setUserLocation] = useState({
    lat: null,
    lng: null
  });

  const { data: hiddenStoreCodesWidgetData } = useGetWidgetQuery(
    'find-in-store-hidden-store-codes'
  );

  const filteredStores = stores?.results?.filter((store) => {
    const storeCodes = hiddenStoreCodesWidgetData?.attributes?.store_codes;
    if (!storeCodes || storeCodes.length === 0) {
      return true;
    }
    return !storeCodes.some((code) => code.value.store_code === store.erp_code);
  });

  return (
    <>
      <div className="container border border-lightBlue-500 p-10 px-4 md:px-10">
        <div className="flex h-full w-full md:gap-x-6">
          <div className="w-full md:w-4/10">
            <StoresList
              stores={{ ...stores, results: filteredStores }}
              cities={cities}
              selectedCityPk={selectedCityPk}
              setSelectedCityPk={setSelectedCityPk}
              selectedStorePk={selectedStorePk}
              setSelectedStorePk={setSelectedStorePk}
              userLocation={userLocation}
              setUserLocation={setUserLocation}
            />
          </div>
          <div className="hidden w-full rounded-xl md:block md:w-6/10">
            <StoresMap
              selectedStorePk={selectedStorePk}
              setSelectedStorePk={setSelectedStorePk}
              userLocation={userLocation}
              stores={{ ...stores, results: filteredStores }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
