'use client';
import { Image } from '@akinon/next/components/image';
import { WorkingWithUsType } from './type';

export default function CarouselWithContentWidget({
  data
}: {
  data: WorkingWithUsType;
}) {
  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return;

  const mainTitle = attributes?.main_title?.value;
  const mainDescription = attributes?.main_description?.value;
  const image = attributes?.image?.kwargs?.url;
  const mobileImage = attributes?.mobile_image?.kwargs?.url;
  const loopTitle = attributes?.loop_title?.value;
  const loop = attributes?.loop_items;

  return (
    <div className="border-b border-lightBlue font-sans text-primary">
      <div className="container flex flex-col gap-4 pb-[2.438rem] md:grid md:grid-cols-[34.375rem_1fr] md:flex-row md:gap-24 md:pb-[4.375rem]">
        {image && (
          <Image
            imageClassName="w-full object-cover"
            className={'hidden lg:block'}
            alt={mainTitle}
            src={image}
            width={550}
            height={568}
          />
        )}
        {mobileImage && (
          <Image
            imageClassName="w-full object-cover"
            className={'lg:hidden'}
            alt={mainTitle}
            src={mobileImage}
            width={343}
            height={248}
          />
        )}
        <div className="flex flex-col gap-4">
          {mainTitle && (
            <div className="text-xl leading-6 md:text-[2.5rem]  md:leading-snug">
              {mainTitle}
            </div>
          )}
          {mainDescription && (
            <div
              className="text-sm leading-snug md:text-base"
              dangerouslySetInnerHTML={{ __html: mainDescription }}
            ></div>
          )}
          {loopTitle && (
            <div className="mt-2 text-base font-medium leading-6 md:mt-6 md:text-[1.75rem] md:leading-snug">
              {loopTitle}
            </div>
          )}
          {loop && loop.length > 0 && (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
              {loop.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-6 border border-lightBlue p-4"
                >
                  <div className="text-[2rem] font-medium leading-snug">
                    {item.value.title}
                  </div>
                  <div className="text-sm leading-snug">
                    {item.value.sub_title}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
