'use client';
import { Image } from '@akinon/next/components/image';
import { GlobalLocationOverviewType } from './type';
import clsx from 'clsx';

export default function GlobalLocationsOveriew({
  data
}: {
  data: GlobalLocationOverviewType;
}) {
  const attributes = data?.attributes;

  if (Object.keys(attributes).length === 0) return;

  const title = attributes?.title?.value;
  const description = attributes?.description?.value;
  const desktopImage = attributes?.image?.kwargs?.url;
  const mobileImage = attributes?.mobile_image?.kwargs?.url;

  return (
    <div
      className={clsx('border-b border-lightBlue-500 py-10', 'lg:py-[72px]')}
    >
      <div className="relative h-full bg-primary">
        <div className="container px-0">
          {desktopImage && (
            <Image
              className="hidden lg:block"
              imageClassName="ml-auto max-h-[280px] w-auto object-contain pt-[44px]"
              alt={title}
              sizes="100vw"
              src={desktopImage}
              width={1440}
              height={875}
            />
          )}
          {(mobileImage || desktopImage) && (
            <Image
              className="block lg:hidden"
              imageClassName="h-auto w-full object-cover"
              alt={title}
              sizes="100vw"
              src={mobileImage || desktopImage}
              width={750}
              height={1334}
            />
          )}
          <div
            className={clsx(
              'absolute bottom-0 flex h-full flex-col justify-center px-4 py-[37px] text-white',
              'lg:max-w-[679px]',
              '2xl:px-0'
            )}
          >
            {title && (
              <h4
                className={clsx(
                  'mb-4 text-[32px] leading-[1.38]',
                  'lg:text-5xl lg:leading-[1.25]'
                )}
              >
                {title}
              </h4>
            )}
            {description && (
              <div
                className={clsx('text-base leading-[1.5]', 'lg:text-xl')}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
