'use client';
import { useState } from 'react';
import Content from './content';
import { TabsData, WidgetData } from './type';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
const GiftsForCategories = ({ data }: WidgetData) => {
  const tabDatas: TabsData = data.attributes?.slugs?.map((slugData) => {
    return {
      slug: slugData?.value.slug,
      tab: slugData?.value.tab
    };
  });

  const firstSlug = tabDatas[0]?.slug;

  const [selectedSlug, setSelectedSlug] = useState(firstSlug);

  const { data: bannersData } = useGetWidgetQuery(selectedSlug);

  if (!bannersData) return null;

  return (
    <section className="border-b border-t mt-10 md:mt-[71px] border-lightBlue py-10 text-primary md:py-[72px]">
      <div className="container">
        {data.attributes?.section_title?.value?.section_title && (
          <h3 className="mb-6 text-2xl tracking-[-0.4px] md:mb-12 md:text-[40px] md:leading-[52px]">
            {data.attributes?.section_title?.value?.section_title}
          </h3>
        )}
        <Content
          setSelectedSlug={setSelectedSlug}
          tabs={tabDatas}
          banners={bannersData?.attributes}
        />
      </div>
    </section>
  );
};

export default GiftsForCategories;
