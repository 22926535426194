'use client';

import { ProductItem } from '../product-item';
import { SwiperReact, SwiperSlide, SwiperNavigation } from '@theme/components';
import clsx from 'clsx';

export default function SingleBannerRecommendationContent({ data }) {
  return (
    <div className="mt-6 pb-4 lg:mt-[54px] lg:pl-0">
      <div className="relative flex items-center">
        <div className="w-full overflow-hidden">
          <SwiperReact
            slidesPerView={2}
            className="w-full"
            spaceBetween={16}
            modules={[SwiperNavigation]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
            onSlideChange={(swiper) => {
              if (swiper.realIndex === 0) return;
              document
                .querySelector('.swiper-button-prev')
                .classList.remove('!hidden');
            }}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 13.3
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 36
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 24
              }
            }}
          >
            {data?.products?.map((product, index) => (
              <SwiperSlide key={product.pk}>
                <ProductItem
                  product={product}
                  width={306}
                  height={450}
                  index={index}
                />
              </SwiperSlide>
            ))}
            <div
              className={clsx(
                `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                `after:!text-[.75rem] after:text-primary after:content-['prev']`,
                ' lg:bg-primary lg:after:text-white'
              )}
            ></div>
            <div
              className={clsx(
                `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                `after:!text-[.75rem] after:text-primary after:content-['next']`,
                'lg:bg-primary lg:after:text-white'
              )}
            ></div>
          </SwiperReact>
        </div>
      </div>
    </div>
  );
}
