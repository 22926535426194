'use client';

import { Image } from '@akinon/next/components/image';
import { useMediaQuery } from '@akinon/next/hooks';
import { Button, Icon, Link } from '@theme/components';
import { pushSelectPromotion, pushViewPromotion } from '@theme/utils/gtm';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const BannerItem = ({ card, index }) => {
  const { ref, inView } = useInView();
  const [viewed, setViewed] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const promotionEvent = useMemo(
    () => ({
      creative_name: card?.kwargs?.value?.image?.url,
      creative_slot: `Four Banner ${index + 1}`,
      promotion_id: `four-banner_${index + 1}`,
      promotion_name: card?.value?.card_title
    }),
    [card, index]
  );

  useEffect(() => {
    if (inView && !viewed) {
      setViewed(true);
      pushViewPromotion(promotionEvent);
    }
  }, [inView, viewed, promotionEvent]);

  const url = card?.value?.url || '#';
  const mobileImage = card?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg';
  const image = card?.kwargs?.value?.image?.url ?? '/noimage.jpg';
  const imageAlt = card?.value?.image_alt;
  const cardTitle = card?.value?.card_title;
  const cardTitleColor = card?.value?.card_title_color;
  const cardButtonText = card?.value?.card_button_text;
  const cardButtonTextColor = card?.value?.card_button_text_color;

  return (
    <>
      <Link
        href={url}
        className="relative"
        onClick={() => pushSelectPromotion(promotionEvent)}
      >
        <Image
          src={mobileImage}
          alt={imageAlt}
          className="h-full w-full object-cover sm:hidden"
          sizes="164px"
          aspectRatio={164 / 164}
          fill
        />

        <Image
          src={image}
          alt={imageAlt}
          className="hidden h-full w-full object-cover sm:block"
          sizes="416px"
          aspectRatio={416 / 300}
          fill
        />

        <div
          ref={ref}
          className="absolute left-3 top-3 text-base text-white lg:left-5 lg:top-5 lg:text-2xl"
        >
          {cardTitle && (
            <h4
              className="mb-1 font-normal lg:mb-3"
              {...(cardTitleColor && { style: { color: cardTitleColor } })}
            >
              {cardTitle}
            </h4>
          )}
          {cardButtonText && (
            <div className="flex items-center gap-x-3">
              <span
                {...(cardButtonTextColor && {
                  style: { color: cardButtonTextColor }
                })}
                className={clsx(
                  'relative lg:text-base',
                  'after:absolute after:inset-x-0 after:bottom-0 after:h-px after:bg-white'
                )}
              >
                {cardButtonText}
              </span>
              <Icon name="chevron-end" size={12} className="text-white" />
            </div>
          )}
        </div>
      </Link>
    </>
  );
};

export default function FourBanner({ data }) {
  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return null;

  const title = attributes?.title?.value;
  const titleColor = attributes?.title_color?.value;
  const button = {
    text: attributes?.button_text?.value,
    url: attributes?.button_url?.value
  };

  return (
    <div
      className={clsx(
        'border-b border-t border-lightBlue py-10',
        'lg:py-[4.5rem]'
      )}
    >
      <div className="container">
        <div className="flex flex-col lg:flex-row lg:gap-x-3">
          <div
            className={clsx(
              'flex flex-1 flex-col items-center justify-center',
              'lg:items-start'
            )}
          >
            {title && (
              <h3
                {...(titleColor && { style: { color: titleColor } })}
                className={clsx(
                  'text-center text-2xl',
                  'px-10 tracking-[-0.4px] lg:px-0 lg:text-left lg:text-[2.25rem] lg:leading-[3.25rem]'
                )}
                dangerouslySetInnerHTML={{ __html: title }}
              ></h3>
            )}
            {button.url && button.text && (
              <Link className="my-6" href={button.url}>
                <Button className="px-7" appearance="filled">
                  {button.text}
                </Button>
              </Link>
            )}
          </div>
          <div className="grid flex-[2] grid-cols-2 gap-4 lg:gap-6">
            {data?.attributes?.cards?.map((card, index) => (
              <BannerItem card={card} key={index} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
