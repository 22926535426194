'use client';

import { Image } from '@akinon/next/components/image';
import {
  Link,
  SwiperNavigation,
  SwiperReact,
  SwiperSlide
} from '@theme/components';
import { InspirationCategoiresType } from './type';
import clsx from 'clsx';

export default function InspirationCategoires({
  data
}: {
  data: InspirationCategoiresType;
}) {
  if (!data?.attributes?.category) return null;
  const categories = data?.attributes?.category;

  return (
    <div className=" border-b border-lightBlue">
      <div className="container mb-6 mt-4">
        <SwiperReact
          slidesPerView="auto"
          className="w-full"
          spaceBetween={0}
          modules={[SwiperNavigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
        >
          {categories?.map((item, index) => (
            <SwiperSlide
              key={index}
              className="!w-[182px] border-r px-4 last:border-none lg:!w-[248px]"
            >
              <div className="flex flex-col items-center">
                <Link
                  href={item?.value?.url}
                  className="flex flex-col items-center"
                >
                  <Image
                    src={item?.kwargs?.value?.image?.url}
                    alt={item?.value?.alt}
                    aspectRatio={1}
                    width={80}
                    height={80}
                    className="max-w-[80px] "
                    imageClassName="rounded-full"
                  />
                  <div className="mt-2 leading-[24px] text-primary">
                    {item?.value?.title}
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}{' '}
          <div
            className={clsx(
              `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['prev']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
          <div
            className={clsx(
              `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['next']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
        </SwiperReact>
      </div>
    </div>
  );
}
