'use client';

import clsx from 'clsx';
import { Image } from '@akinon/next/components/image';
import { InspirationBannerType } from './type';
import React from 'react';
import { Button, Link } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';

export default function InspirationBanner({
  data
}: {
  data: InspirationBannerType;
}) {
  const { t } = useLocalization();
  if (!data?.attributes) return null;
  const attr = data?.attributes?.banner;
  return (
    <div className="border-b border-lightBlue">
      <div className="container relative my-10 lg:my-16">
        <Image
          imageClassName="w-[343px] h-[250px]"
          className="block h-[250px] w-full object-cover object-center lg:hidden"
          alt={attr?.value?.alt}
          src={attr?.kwargs?.value?.mobile_image?.url}
          aspectRatio={343 / 250}
          sizes="343px"
          fill
        />

        <Image
          imageClassName="w-[1296px] h-[400px]"
          className="hidden h-[400px] w-full object-cover object-center lg:block"
          alt={attr?.value?.alt}
          src={attr?.kwargs?.value?.image?.url}
          aspectRatio={1296 / 400}
          sizes="1296px"
          fill
        />
        <div className="absolute left-4 top-0 ">
          <p
            className={clsx(
              'm-4 w-[276px] text-2xl leading-[1.33] text-primary ',
              ' lg:mx-6 lg:my-[34px] lg:w-[420px] lg:text-[32px] lg:leading-[1.38]'
            )}
          >
            {attr?.value?.title}
          </p>
          <Link
            href={attr?.value?.url}
            className={clsx('left-4 top-0 w-[inherit]', '')}
          >
            <Button
              appearance="filled"
              className={clsx(
                'mx-4 h-10 text-base leading-[1.5]',
                'lg:left-2 lg:top-[192px]'
              )}
            >
              {t('flat_pages.inspiration.banner.button')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
