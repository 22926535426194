'use client';
import { Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import { useSession } from 'next-auth/react';
import { SwiperReact, SwiperSlide, SwiperNavigation } from '@theme/components';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type Props = {
  data: any;
  containerClassName?: string;
};

export default function FooterClupCart({ data, containerClassName }: Props) {
  const {
    attributes: { button, images, title }
  } = data || {};
  const { data: loginInformation } = useSession();
  const generateHtml = (data: string) => {
    return { __html: data };
  };

  return (
    <div className={twMerge('border-t border-t-lightBlue', containerClassName)}>
      <div className="mx-[15px] flex flex-col items-start justify-between py-[40px] md:container lg:mb-0 lg:flex-row lg:items-center lg:pb-[72px] lg:pt-[54px]">
        <div className="flex flex-col">
          <div
            className="mb-[3px] flex h-16 max-w-[343px] items-center justify-center text-2xl leading-[32px] lg:mb-0 lg:h-[88px] lg:max-w-[526px] lg:text-[32px] lg:leading-[43px] [&>p]:h-[88px]"
            dangerouslySetInnerHTML={generateHtml(title?.value)}
          />

          <div className="mt-6 flex gap-4 text-base">
            {loginInformation === null && (
              <Link
                href="/"
                className="flex h-8 w-[146px] items-center justify-center bg-primary text-sm text-white lg:h-12 lg:text-base"
              >
                Üyelik Oluştur
              </Link>
            )}

            {button?.value?.button_text && (
              <Link
                href={button?.value?.button_link}
                className="flex h-8 w-[146px] items-center justify-center border border-primary text-sm lg:h-12 lg:text-base"
              >
                {button?.value?.button_text}
              </Link>
            )}
          </div>
        </div>
        <div className={clsx('relative max-w-full lg:flex lg:gap-6')}>
          {images.map((image, id) => (
            <div className="relative hidden lg:block" key={id}>
              <Image
                src={image?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                alt={image?.value?.image_text}
                width={230}
                height={230}
                imageClassName="h-[230px]"
              />
              <div
                dangerouslySetInnerHTML={generateHtml(image?.value?.image_text)}
                className="absolute left-[50%] top-[50%] w-full translate-x-[-50%] translate-y-[-50%] text-center text-lg text-white lg:text-2xl"
              />
            </div>
          ))}
          <div className="mt-6 lg:hidden">
            <SwiperReact
              modules={[SwiperNavigation]}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              slidesPerView={2.174}
              grid={{
                rows: 1,
                fill: 'row'
              }}
              spaceBetween={16}
              className="max-w-full !overflow-visible"
            >
              {images?.map((image, id) => (
                <SwiperSlide key={id} className="relative">
                  <Image
                    src={image?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                    alt={image?.value?.image_text}
                    aspectRatio={230 / 230}
                    sizes="100vw"
                    imageClassName="object-cover w-full"
                    width={230}
                    height={230}
                    className="hidden lg:block"
                  />
                  <Image
                    src={
                      image?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg'
                    }
                    alt={image?.value?.image_text}
                    aspectRatio={310 / 310}
                    sizes="100vw"
                    imageClassName="object-cover w-full"
                    width={310}
                    height={310}
                    className="block lg:hidden"
                  />
                  {image?.value?.image_text && (
                    <div
                      dangerouslySetInnerHTML={generateHtml(
                        image?.value?.image_text
                      )}
                      className="absolute left-[50%] top-[50%] w-full translate-x-[-50%] translate-y-[-50%] text-center text-sm text-white lg:text-lg"
                    />
                  )}
                </SwiperSlide>
              ))}
              <div
                className={clsx(
                  `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                  `after:!text-[.75rem] after:text-primary after:content-['prev']`,
                  'lg:bg-primary lg:after:text-white'
                )}
              ></div>
              <div
                className={clsx(
                  `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                  `after:!text-[.75rem] after:text-primary after:content-['next']`,
                  'lg:bg-primary lg:after:text-white'
                )}
              ></div>
            </SwiperReact>
          </div>
        </div>
      </div>
    </div>
  );
}
