'use client';

import { Image } from '@akinon/next/components/image';
import ContactForm from '@theme/views/account/contact-form';

const ContactUsForm = ({ data }) => {
  const contactImage = data?.attributes?.contact_us?.kwargs?.value?.image?.url;
  const contactImageAlt = data?.attributes?.contact_us?.value?.alt;
  const hexCode = data?.attributes?.contact_us?.value?.hex_code;

  return (
    <div
      className="w-full lg:py-10"
      {...(hexCode && { style: { backgroundColor: hexCode } })}
    >
      <div className="container justify-center shadow-sm md:flex lg:my-10">
        <ContactForm />
        {contactImage && (
          <div className="hidden w-full max-w-[550px] lg:block">
            <Image
              imageClassName="w-full h-full object-cover"
              className="!block h-full"
              alt={contactImageAlt}
              src={contactImage}
              aspectRatio={550 / 788}
              sizes="550px"
              fill
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUsForm;
