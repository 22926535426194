'use client';
import { Button, Icon, SwiperReact, SwiperSlide } from '@theme/components';
import { CarouselItem } from './type';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Swiper from 'swiper';

type SwiperStateType = {
  isEnd?: boolean;
  isBeginning?: boolean;
};

export default function CarouselContentItems({
  items
}: {
  items: CarouselItem[];
}) {
  const swiperRef = useRef(null);

  const [swiperState, setSwiperState] = useState<SwiperStateType>({
    isBeginning: true
  });

  return (
    <SwiperReact
      ref={swiperRef}
      onSlideChange={({ isEnd, isBeginning }: Swiper) => {
        setSwiperState({ isEnd, isBeginning });
      }}
      className={clsx(
        '!px-4',
        '2xl:!px-[72px] 2xl:!pl-[calc(calc(100%-1296px)/2)]'
      )}
      spaceBetween={16}
      slidesPerView="auto"
      breakpoints={{
        '768': {
          slidesPerView: 3.0032,
          spaceBetween: 24
        }
      }}
    >
      {items?.map((item, index) => {
        const title = item.value.title;
        const secondTitle = item.value.second_title;
        const image = item?.kwargs?.value?.image?.url;
        const mobileImage = item?.kwargs?.value?.mobile_image?.url;
        return (
          <SwiperSlide
            className="!w-fit lg:max-w-[416px]"
            key={`carousel-content-slide-${index}`}
          >
            <div className="relative">
              {image && (
                <Image
                  imageClassName="w-full h-full object-cover"
                  className={clsx('hidden', 'lg:block lg:h-[296px] lg:w-full')}
                  alt={title}
                  src={image}
                  width={416}
                  height={296}
                />
              )}
              {mobileImage && (
                <Image
                  imageClassName="w-full h-full object-cover"
                  className={clsx('h-[200px] w-[300px]', 'lg:hidden')}
                  alt={title}
                  src={mobileImage}
                  width={300}
                  height={200}
                />
              )}
              <div
                className={clsx(
                  'absolute bottom-6 left-4 pr-4 text-white',
                  'lg:bottom-10 lg:left-8 lg:pr-[60px]'
                )}
              >
                {title && (
                  <h3
                    className={clsx(
                      'mb-1 text-2xl leading-[1.33]',
                      'lg:text-[32px] lg:leading-[1.38]'
                    )}
                  >
                    {title}
                  </h3>
                )}
                {secondTitle && (
                  <h4
                    className={clsx(
                      'text-xl leading-[1.5]',
                      'lg:text-2xl lg:leading-[1.33]'
                    )}
                  >
                    {secondTitle}
                  </h4>
                )}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
      <Button
        className={clsx(
          'absolute left-[72px] top-1/2 z-10 hidden !h-8 !w-8 -translate-y-1/2 transform rounded-full border-white bg-white p-1 text-primary',
          'lg:block',
          { '!hidden': swiperState?.isBeginning }
        )}
        onClick={() => {
          swiperRef?.current?.swiper.slidePrev();
        }}
      >
        <Icon
          className="justify-center text-primary"
          name="chevron-start"
          size={14}
        />
      </Button>
      <Button
        className={clsx(
          'absolute right-[72px] top-1/2 z-10 hidden !h-8 !w-8 -translate-y-1/2 transform rounded-full border-white bg-white p-1 text-primary',
          'lg:block',
          { '!hidden': swiperState?.isEnd }
        )}
        onClick={() => {
          swiperRef?.current?.swiper.slideNext();
        }}
      >
        <Icon
          className="justify-center text-primary"
          name="chevron-end"
          size={14}
        />
      </Button>
    </SwiperReact>
  );
}
