'use client';
import { Image } from '@akinon/next/components/image';
import { GiftListCreationSectionType } from './type';
import { Link, Button } from '@theme/components';
import { useLocalization, useMediaQuery } from '@akinon/next/hooks';
import { ROUTES } from '@theme/routes';

import clsx from 'clsx';

export const GiftListCreationSection = ({
  data
}: {
  data: GiftListCreationSectionType;
}) => {
  const { t } = useLocalization();

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const attributes = data?.attributes;
  const textColor = attributes?.main_image?.value?.title_color || 'text-black';
  const mainImageUrl = isMobile
    ? attributes?.main_image?.kwargs?.value?.mobile_image?.url
    : attributes?.main_image?.kwargs?.value?.image?.url;

  if (Object.keys(attributes || {}).length === 0) return;

  return (
    <div className="border-y border-lightBlue">
      <div className="container my-10 lg:my-[72px]">
        <div className={clsx('grid grid-cols-1 ', 'lg:grid-cols-2')}>
          <div className="border-lightblue border-b lg:flex lg:flex-col lg:justify-center lg:border-b-0">
            <div
              className={clsx(
                'pb-10',
                'lg:border-b-0 lg:border-r lg:px-10 lg:pb-7 lg:pr-10'
              )}
            >
              <span
                className={clsx(
                  'block text-center text-2xl font-normal leading-8',
                  'lg:text-5xl lg:leading-[60px]'
                )}
              >
                {t('flat_pages.gift_list_creation.create.title')}
              </span>
              <span
                className={clsx(
                  'mt-1 block text-center text-sm font-normal leading-5',
                  'lg:mt-2 lg:text-2xl lg:leading-8'
                )}
              >
                {t('flat_pages.gift_list_creation.create.sub_title')}
              </span>
              <div className={clsx('mt-4 flex flex-col gap-3', 'lg:gap-4')}>
                <Link href={ROUTES.GIFT_LISTS}>
                  <Button
                    appearance="filled"
                    className={clsx(
                      'mx-auto  flex h-10 w-full items-center justify-center text-base font-normal capitalize leading-6',
                      'lg:max-w-[400px]'
                    )}
                    data-testid="gift-list-creation-create-new"
                  >
                    {t('flat_pages.gift_list_creation.create.create_new')}
                  </Button>
                </Link>
                <Link href={ROUTES.GIFT_LISTS}>
                  <Button
                    appearance="outlined"
                    className={clsx(
                      'mx-auto flex h-10 w-full items-center justify-center text-base font-normal capitalize leading-6',
                      'lg:max-w-[400px]'
                    )}
                    data-testid="gift-list-creation-go-list"
                  >
                    {t('flat_pages.gift_list_creation.create.go_list')}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="border-lightblue border-b lg:border-b-0">
            <div
              className={clsx(
                'flex flex-col pb-10',
                ' lg:border-b-0 lg:pb-7 lg:pl-10'
              )}
            >
              <div className="relative h-[235px] min-h-[235px] w-full lg:h-[260px] lg:min-w-[280px]">
                {mainImageUrl && (
                  <div
                    style={{
                      backgroundImage: `url(${mainImageUrl})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      aspectRatio: isMobile ? '736/235' : '608/260'
                    }}
                    className="aspect-[736/235] h-full w-full lg:aspect-[608/260]"
                  />
                )}
                <div className="absolute top-4 flex w-full flex-col px-4 lg:top-6 lg:px-6">
                  {attributes?.main_image?.value?.title && (
                    <span
                      style={{ color: textColor }}
                      className={clsx(
                        'text-lg leading-7',
                        'lg:text-2xl lg:leading-8'
                      )}
                    >
                      {attributes?.main_image?.value?.title}
                    </span>
                  )}
                  {attributes?.main_image?.value?.button_title && (
                    <Link href={attributes?.main_image?.value?.link ?? '#'}>
                      <Button
                        appearance="filled"
                        className={clsx(
                          'mt-4 !h-[33px] w-[150px] !p-0 text-[13px] font-normal leading-[19px]'
                        )}
                        data-testid="gift-list-creation-create-new"
                      >
                        {attributes?.main_image?.value?.button_title}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-[9px] lg:mt-4 lg:gap-[24px] 2xl:grid-cols-2">
                {attributes?.list_images.map((list, index) => (
                  <div
                    key={index}
                    className={clsx('flex grid-cols-2 flex-row px-4 lg:px-0')}
                  >
                    <Link
                      href={list?.value?.link ?? '#'}
                      className="h-[100px] min-h-[100px] w-[120px] min-w-[120px]
                      lg:h-[150px] lg:min-h-[150px] lg:w-[150px] lg:min-w-[150px]"
                    >
                      <Image
                        src={
                          isMobile
                            ? list?.kwargs?.value?.mobile_image?.url
                            : list?.kwargs?.value?.image?.url
                        }
                        alt={list?.value?.title}
                        width={isMobile ? 120 : 150}
                        height={isMobile ? 100 : 150}
                        className="object-cover"
                        imageClassName="h-[100px] lg:h-[150px] w-[120px] lg:w-[150px]
                      min-w-[120px] min-h-[100px] lg:min-w-[150px] lg:min-h-[150px]"
                      />
                    </Link>

                    <div className="mx-2 flex flex-col">
                      {list?.value?.name_surname && (
                        <span
                          className={clsx(
                            'mt-2 text-sm leading-5 text-gray-700',
                            'lg:text-base lg:leading-6'
                          )}
                        >
                          {list?.value?.name_surname}
                        </span>
                      )}
                      {list?.value?.title && (
                        <Link href={list?.value?.link ?? '#'}>
                          <span
                            className={clsx(
                              'mt-[2px] text-sm font-medium leading-5 text-primary',
                              'lg:mt-1 lg:text-base lg:leading-7'
                            )}
                          >
                            {list?.value?.title}
                          </span>
                        </Link>
                      )}
                      {list?.value?.created_date && (
                        <span
                          className={clsx(
                            'mb-2 mt-auto text-sm leading-[18px] text-gray-700',
                            'lg:mb-0 lg:leading-5'
                          )}
                        >
                          {new Date(list.value.created_date).toLocaleDateString(
                            'tr-TR',
                            {
                              year: 'numeric',
                              month: 'long'
                            }
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={clsx('mt-8', 'lg:mt-12')}>
          {attributes?.main_title?.value && (
            <div
              className={clsx(
                'text-xl leading-[30px]',
                'lg:text-center lg:text-[40px] lg:leading-[52px]'
              )}
            >
              {attributes?.main_title?.value}
            </div>
          )}
          <div
            className={clsx(
              'grid-col-1 mt-4 grid gap-3',
              'lg:mt-8 lg:grid-cols-2 lg:gap-6'
            )}
          >
            {attributes?.reason?.map((reason, index) => (
              <div
                className={clsx('bg-secondary-50 px-4 py-3 lg:p-6')}
                key={index}
              >
                {reason?.kwargs?.value?.image?.url && (
                  <Image
                    src={reason?.kwargs?.value?.image?.url}
                    alt={reason.value.title}
                    className="mx-auto flex h-[32px] w-[32px] lg:h-[64px] lg:w-[64px]"
                    fill
                    aspectRatio={1 / 1}
                    sizes="64px"
                  />
                )}
                {reason.value.title && (
                  <span
                    className={clsx(
                      'mt-2 block text-center text-base font-medium leading-6',
                      'lg:mt-4 lg:text-2xl lg:leading-8'
                    )}
                  >
                    {reason.value.title}
                  </span>
                )}
                {reason.value.sub_title && (
                  <span
                    className={clsx(
                      'mt-1 block text-center text-sm font-normal leading-5',
                      'lg:mt-3 lg:text-lg lg:leading-7'
                    )}
                  >
                    {reason.value.sub_title}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftListCreationSection;
