'use client';
import clsx from 'clsx';
import { FourBlocksWithContentType } from './type';
import { Icon, Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';

export default function FourBlockWithContent({
  data
}: {
  data: FourBlocksWithContentType;
}) {
  const attributes = data?.attributes;

  if (Object.keys(attributes).length === 0) return;

  const title = attributes?.title?.value;
  const buttonLabel = attributes?.button_label?.value;
  const buttonUrl = attributes?.button_url?.value;
  const items = attributes?.items;

  return (
    <div
      className={clsx('border-b border-lightBlue-500 py-10', 'lg:py-[72px]')}
    >
      <div className="container">
        <div className={clsx('mb-6 text-center', 'lg:mb-10')}>
          {title && (
            <h4
              className={clsx(
                'mb-3 text-2xl leading-[1.33] text-primary',
                'leading-[1.3] -tracking-[0.4px] lg:mb-2 lg:text-[40px]'
              )}
            >
              {title}
            </h4>
          )}
          {buttonLabel && (
            <div className="flex items-center justify-center gap-2">
              <Link
                className={clsx(
                  'h-auto border-b border-primary pb-0.5 text-sm leading-[1.43]',
                  'pb-[1.5px] lg:mb-[9px] lg:pt-[7px] lg:text-base lg:leading-[1.5]'
                )}
                href={buttonUrl}
              >
                {buttonLabel}
              </Link>
              <Icon className="hidden lg:block" name="arrow-right" size={24} />
            </div>
          )}
        </div>

        {items?.length && (
          <div className={clsx('grid grid-cols-2 gap-4', 'lg:gap-6')}>
            {items?.map((item, index) => {
              const mobileImage = item?.kwargs?.value?.mobile_image?.url;
              const desktopImage = item?.kwargs?.value?.image?.url;
              const title = item?.value?.title;
              const url = item?.value?.image_url;
              return (
                <Link
                  href={url}
                  key={`four-block-${index}`}
                  className="relative"
                >
                  {desktopImage && (
                    <Image
                      className="hidden lg:block"
                      imageClassName="h-auto w-full object-cover"
                      alt={title}
                      sizes="100vw"
                      src={desktopImage}
                      width={1440}
                      height={875}
                    />
                  )}
                  {(mobileImage || desktopImage) && (
                    <Image
                      className="block lg:hidden"
                      imageClassName="h-auto w-full object-cover"
                      alt={title}
                      sizes="100vw"
                      src={mobileImage || desktopImage}
                      fill
                      aspectRatio={1 / 1}
                    />
                  )}
                  <div
                    className={clsx(
                      'absolute left-0 top-0 h-full w-full p-3 text-white',
                      'lg:px-6 lg:py-10'
                    )}
                  >
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: title
                      }}
                      className={clsx(
                        'text-base font-medium leading-[1.5]',
                        'lg:text-2xl lg:leading-[1.33]'
                      )}
                    ></h4>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
