'use client';
import { Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';

export default function DoubleBanner() {
  const { data } = useGetWidgetQuery('home-double-banner');

  return (
    <section>
      <div className="grid gap-y-5 pb-10 pt-5 md:grid-cols-2 md:gap-x-1.5 md:pb-14 md:pt-7">
        {data?.attributes?.double_banner?.length > 0 &&
          data?.attributes?.double_banner?.map((item, i) => (
            <div key={i} className="relative">
              <Link href={item?.value?.url ?? '#'} className="flex">
                <Image
                  src={item?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg'}
                  alt={item?.value?.alt}
                  className="md:hidden"
                  width={375}
                  height={400}
                  aspectRatio={1}
                  imageClassName="w-full"
                  sizes="100vw"
                />

                <Image
                  src={item?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                  alt={item?.value?.alt}
                  className="hidden md:block"
                  fill
                  aspectRatio={717 / 450}
                  sizes="100vw"
                />
              </Link>
            </div>
          ))}
      </div>
    </section>
  );
}
