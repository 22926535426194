'use client';
import { Image } from '@akinon/next/components/image';
import { OpenPositionsType } from './type';
import { useLocalization } from '@akinon/next/hooks';
import { Button, Icon, Link } from '@theme/components';

export default function OpenPositionsWidget({
  data
}: {
  data: OpenPositionsType;
}) {
  const attributes = data?.attributes;
  const { t } = useLocalization();

  if (Object.keys(attributes || {}).length === 0) return;

  const mainTitle = attributes?.main_title?.value;
  const mobileButtonUrl = attributes?.mobile_button_url?.value;
  const image = attributes?.image?.kwargs?.url;
  const mobileImage = attributes?.mobile_image?.kwargs?.url;
  const loop = attributes?.loop_items;

  return (
    <div className="mt-10 border-b border-lightBlue font-sans text-primary md:mt-[4.375rem]">
      <div className="container flex flex-col gap-4 pb-[2.438rem] md:grid md:grid-cols-[34.375rem_1fr] md:flex-row md:gap-24 md:pb-[4.375rem]">
        <div className="flex flex-col gap-4 md:gap-10">
          {mainTitle && (
            <div className="text-xl leading-normal md:text-[2rem]  md:leading-snug">
              {mainTitle}
            </div>
          )}
          {mobileImage && (
            <Image
              imageClassName="w-full object-cover"
              className={'lg:hidden'}
              alt={mainTitle}
              src={mobileImage}
              width={343}
              height={248}
            />
          )}
          <div className="flex flex-col gap-4 md:gap-6">
            {loop.map((item, index) => (
              <div
                key={index}
                className="flex items-end justify-between gap-6 border-b border-lightBlue pb-4 md:p-6"
              >
                <div>
                  <div className="text-base font-medium leading-snug text-black md:text-lg">
                    {item.value.title}
                  </div>
                  <div className="text-xs leading-snug text-gray-700 md:text-sm">
                    {item.value.sub_title}
                  </div>
                </div>
                <Link href={item.value.url} className="hidden md:block">
                  <Button className="text-base leading-6 text-white">
                    {t('flat_pages.career.button_type_one')}
                  </Button>
                </Link>
              </div>
            ))}
          </div>
          <Link href={mobileButtonUrl} className="mt-4 w-full md:hidden">
            <Button className="flex !h-12 w-full items-center justify-center gap-2 !text-base leading-6 !text-white">
              {t('flat_pages.career.button_type_two')}
              <Icon name="arrow-right" size={24} />
            </Button>
          </Link>
        </div>
        {image && (
          <Image
            imageClassName="w-full object-cover"
            className={'hidden lg:block'}
            alt={mainTitle}
            src={image}
            width={550}
            height={444}
          />
        )}
      </div>
    </div>
  );
}
