'use client';
import { Icon } from '@akinon/next/components/icon';
import { Image } from '@akinon/next/components/image';
import { Link } from '@akinon/next/components/link';
import { Product } from '@akinon/next/types';
import { Button } from '@theme/components';
import { twMerge } from 'tailwind-merge';
import { useAddProductToBasket } from '@theme/hooks';

type Props = {
  className?: string;
  product: Product;
};

const MiniProductCard = ({ className, product }: Props) => {
  const [addProductToBasket] = useAddProductToBasket();

  if (!product) return null;

  const addToBasketHandler = () => {
    addProductToBasket({
      product: product.pk,
      quantity: 1,
      attributes: {},
      sku: product.sku
    });
  };

  return (
    <div className={twMerge('shrink-0', className)}>
      <Link href={product.absolute_url} className="mb-3 block md:mb-2">
        <Image
          src={product.productimage_set[0]?.image}
          alt={product.name}
          aspectRatio={306 / 235}
          sizes="(max-width: 768px) 100vw, 306px"
          fill
          className="mb-[1px] min-h-[144px] rounded-[2px]"
        />
        <p className="max-h-[21px] pl-3 text-sm leading-[21px] text-primary md:pl-0 md:text-base md:leading-[21px]">
          <b className="mb-[2px] block font-bold md:mb-0 md:inline-block"></b>
          <span>{product.name}</span>
        </p>
      </Link>
      <div className="flex items-end justify-between pl-3 text-primary md:pl-0">
        <span className="text-base font-bold md:text-xl md:leading-[26px]">
          {product.price} TL
        </span>
        <Button
          onClick={addToBasketHandler}
          className="flex h-8 w-8 items-center justify-center rounded-full p-0 md:h-10 md:w-10 lg:h-10 lg:w-10"
        >
          <Icon name="plus" size={13} />
        </Button>
      </div>
    </div>
  );
};

export default MiniProductCard;
