'use client';
import { Button, Icon, SwiperReact, SwiperSlide } from '@theme/components';
import { ProductItem } from '@theme/views/product-item';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { AccountPageRecommendation } from './type';

export default function AccountRecommendationProducts({
  data
}: {
  data: AccountPageRecommendation;
}) {
  const swiperRef = useRef(null);

  const [sliderCount, setSliderCount] = useState<number>(0);

  useEffect(() => {
    if (swiperRef.current) {
      setSliderCount(swiperRef.current.swiper?.slides?.length);
      swiperRef.current?.swiper?.update();
    }
  }, []);

  return (
    <div className="relative">
      <SwiperReact
        ref={swiperRef}
        slidesPerView={1.915}
        className="flex"
        spaceBetween={16}
        breakpoints={{ 768: { slidesPerView: 3.5, spaceBetween: 24 } }}
      >
        {data?.products &&
          data?.products?.length > 0 &&
          data?.products?.map((product, index: number) => {
            return (
              <SwiperSlide
                className="flex-shrink-0 first:pl-4 last:pr-4 lg:pl-0 lg:pr-0"
                key={`account-recommendation-product-${index}`}
              >
                <ProductItem
                  width={306}
                  height={450}
                  index={index}
                  product={product}
                />
              </SwiperSlide>
            );
          })}
      </SwiperReact>

      <Button
        onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
        className={clsx(
          'absolute right-4 top-1/2 z-30 grid !h-8  !min-h-8 !min-w-8 max-w-80 translate-y-1/2 transform items-center rounded-full border-0 bg-gray-100 p-0 text-primary outline-0',
          { hidden: sliderCount < 2 },
          { 'lg:hidden': sliderCount < 4 }
        )}
      >
        <Icon className="justify-center" name="chevron-end" size={14} />
      </Button>
    </div>
  );
}
