'use client';

import clsx from 'clsx';
import { CustomerServiceBlocksType } from './type';
import { Icon, Link } from '@theme/components';

export default function CustomerServiceBlocksWidget({
  data
}: {
  data: CustomerServiceBlocksType;
}) {
  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return null;

  const title = attributes?.title?.value;
  const blocks = attributes?.blocks;

  return (
    <div
      className={clsx('border-b border-lightBlue-500 py-10', 'lg:py-[72px]')}
    >
      <div className="container">
        {title && (
          <h4
            className={clsx(
              'mb-6 px-4 text-center text-2xl leading-[1.33] text-primary',
              'lg:mb-10 lg:px-0 lg:text-left lg:text-[32px] lg:leading-[1.38]'
            )}
          >
            {title}
          </h4>
        )}
        {blocks?.length > 0 && (
          <div
            className={clsx(
              'grid grid-cols-1 gap-4',
              'lg:grid-cols-2 lg:gap-6'
            )}
          >
            {blocks.map((block, index) => {
              const title = block?.value?.title;
              const description = block?.value?.description;
              const buttonLink = block?.value?.button_link;
              const ekoccsChat = title?.toLowerCase() === 'dijital asistan';

              return (
                <div
                  className={clsx(
                    'border border-lightBlue-500 px-4 pb-4 pt-6',
                    'lg:px-6 lg:pb-6 lg:pt-10'
                  )}
                  key={`customer-service-block-${index}`}
                >
                  {title && (
                    <h5
                      className={clsx(
                        'mb-3 text-xl font-medium leading-[1.5] text-primary',
                        'lg:mb-6 lg:text-[28px] lg:leading-[1.29]'
                      )}
                    >
                      {title}
                    </h5>
                  )}
                  {description && (
                    <div
                      className={clsx(
                        'prose mb-3 text-sm text-primary',
                        'lg:mb-6 lg:text-lg'
                      )}
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                  )}
                  {buttonLink && (
                    <Link
                      className="float-right inline-grid h-8 w-8 items-center rounded-full border border-primary lg:h-10 lg:w-10"
                      href={buttonLink}
                    >
                      <Icon
                        className="justify-center lg:!text-[30px]"
                        size={20}
                        name="arrow-right"
                      />
                    </Link>
                  )}
                  {ekoccsChat && (
                    <div
                      className={clsx(
                        'float-right inline-grid h-8 w-8 items-center rounded-full border border-primary lg:h-10 lg:w-10 cursor-pointer'
                      )}
                      onClick={() => {
                        window?.EKOCCSChat?.toggleChatBox?.();
                      }}
                    >
                      <Icon
                        className="justify-center lg:!text-[30px]"
                        size={20}
                        name="arrow-right"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
