'use client';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Skeleton, SkeletonWrapper } from '@theme/components';
import { useCallback, useEffect, useState } from 'react';
import selectedMapMarker from '@theme/assets/icons/store-pin-selected.svg';
import mapMarker from '@theme/assets/icons/store-pin.svg';

const containerStyle = {
  width: '100%',
  height: '640px'
};

export const StoresMap = (props) => {
  const { stores, selectedStorePk, setSelectedStorePk, userLocation } = props;

  const selectedStore = stores.results.find(
    (store) => store.pk.toString() === selectedStorePk
  );

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBmKyHOy4dPb2SdGaCYLtiE0EjCpcmBQ6g'
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback((map) => setMap(map), []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map && stores?.results) {
      const bounds = new window.google.maps.LatLngBounds();

      stores?.results?.map((marker) => {
        bounds.extend({
          lat: Number(marker.latitude),
          lng: Number(marker.longitude)
        });
      });

      const initialCenter = {
        lat: parseFloat(selectedStore?.latitude),
        lng: parseFloat(selectedStore?.longitude)
      };

      setTimeout(() => {
        if (selectedStore) {
          map.panTo(initialCenter);
          map.setZoom(15);
        }
      }, 500);

      map.fitBounds(bounds);
    }
  }, [stores?.results, map, selectedStore]);

  useEffect(() => {
    if (userLocation.lat && userLocation.lng && map) {
      const initialCenter = {
        lat: userLocation?.lat,
        lng: userLocation?.lng
      };
      map.panTo(initialCenter);
      map.setZoom(9);
    }
  }, [userLocation, map]);

  return (
    <>
      {isLoaded && window.google !== undefined ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{ minZoom: 2, maxZoom: 15 }}
        >
          {userLocation && map && (
            <Marker
              icon={{
                fillColor: '#4285F4',
                fillOpacity: 1,
                path: google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: 'rgb(255,255,255)',
                strokeWeight: 2
              }}
              position={{
                lat: userLocation.lat,
                lng: userLocation.lng
              }}
            />
          )}
          {stores?.results &&
            stores?.results?.map((store, index) => (
              <Marker
                key={index}
                icon={{
                  url:
                    store.pk.toString() === selectedStorePk
                      ? `${selectedMapMarker.src?.toString()}`
                      : `${mapMarker.src?.toString()}`,
                  size: new window.google.maps.Size(48, 48),
                  scaledSize: new window.google.maps.Size(48, 48)
                }}
                position={{
                  lat: parseFloat(store.latitude),
                  lng: parseFloat(store.longitude)
                }}
                onClick={() => {
                  setSelectedStorePk(store.pk.toString());
                }}
              />
            ))}
        </GoogleMap>
      ) : (
        <>
          <SkeletonWrapper>
            <Skeleton className="h-[445px] w-full md:mt-0" />
          </SkeletonWrapper>
        </>
      )}
    </>
  );
};
