'use client';

import { Image } from '@akinon/next/components/image';
import {
  Button,
  Link,
  SwiperNavigation,
  SwiperReact,
  SwiperSlide
} from '@theme/components';
import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { DesignersType } from './type';
import { useLocalization } from '@akinon/next/hooks';

export default function DesignersWidget({ data }: { data: DesignersType }) {
  const attributes = data?.attributes;
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);

  const { t } = useLocalization();

  if (Object.keys(attributes || {}).length === 0) return;

  const designers = attributes?.designers;

  if (!Array.isArray(designers) || designers.length === 0) return;

  const getAlphabet = (): string[] => {
    return Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  };

  const letters = getAlphabet();

  const filteredDesigners = designers.filter((designer) => {
    if (selectedLetter) {
      return designer?.value?.name?.startsWith(selectedLetter);
    }
    return designer;
  });

  return (
    <section data-id="designers">
      <div className="container mb-6 lg:mb-8">
        <SwiperReact
          slidesPerView={6.5}
          slidesPerGroup={4}
          spaceBetween={16}
          breakpoints={{
            1024: {
              slidesPerView: 'auto',
              slidesPerGroup: 5
            }
          }}
          className='!pb-9'
          modules={[SwiperNavigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
        >
          {letters.map((letter) => {
            const hasDesigner = designers.some((designer) =>
              designer?.value?.name?.startsWith(letter)
            );
            return (
              <SwiperSlide
                key={`letter-${letter}`}
                className="!h-10 !w-10 lg:!h-12 lg:!w-12"
              >
                <button
                  onClick={() => {
                    setSelectedLetter((prev) => {
                      if (prev === letter) return null;
                      return letter;
                    });
                  }}
                  className={clsx(
                    'h-full w-full rounded-full bg-gray-100',
                    {
                      'pointer-events-none cursor-default bg-gray-400 text-gray-500':
                        !hasDesigner
                    },
                    { 'bg-primary text-white': selectedLetter === letter }
                  )}
                >
                  {letter}
                </button>
              </SwiperSlide>
            );
          })}
          <div
            className={clsx(
              `swiper-button-prev !bottom-0 !mt-auto swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['prev']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
          <div
            className={clsx(
              `swiper-button-next !bottom-0 !mt-auto swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['next']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
        </SwiperReact>
      </div>
      <div className="container">
        <h4 className="mb-4 hidden text-2xl font-medium lg:block">
          {t('flat_pages.designers.all_designers')} ({filteredDesigners.length})
        </h4>
      </div>
      <div className="lg:container lg:mb-8 lg:grid lg:grid-cols-3 lg:gap-x-6">
        {filteredDesigners.map((designer, index) => {
          const designerInfo = {
            profileImage: designer?.kwargs?.value?.profile_image?.url,
            name: designer?.value?.name,
            country: designer?.value?.country,
            designCount: designer?.value?.design_count
          };

          const images = Array.from({ length: 4 }, (_, i) => i + 1).map((i) => {
            return designer?.kwargs?.value[`image_${i}`]?.url;
          });

          const mobileImages = Array.from({ length: 4 }, (_, i) => i + 1).map(
            (i) => {
              return designer?.kwargs?.value[`mobile_image_${i}`]?.url;
            }
          );

          const button = {
            label: designer?.value?.button_label,
            url: designer?.value?.button_url
          };

          return (
            <Fragment key={`designer-${index}`}>
              <div className="container pb-10 lg:max-w-max lg:px-0">
                <div className="flex h-full flex-col gap-4 border border-lightBlue-500 p-4 lg:gap-6 lg:p-6">
                  <div className="flex gap-3">
                    {designerInfo.profileImage && (
                      <Image
                        width={40}
                        height={40}
                        alt={designerInfo?.name}
                        src={designerInfo.profileImage}
                      />
                    )}
                    <div className=" flex flex-col">
                      {designerInfo.name && (
                        <h5 className="text-base font-medium">
                          {designerInfo.name}
                        </h5>
                      )}
                      {designerInfo.country && (
                        <p className="text-sm text-gray-600">
                          {designerInfo.country}
                        </p>
                      )}
                    </div>
                    {designerInfo.designCount && (
                      <span className="ml-auto text-sm  text-gray-600">
                        {designerInfo.designCount}
                      </span>
                    )}
                  </div>
                  <div className="grid h-full grid-cols-2 gap-3">
                    {images.map((image, index) => {
                      if (!image) return null;
                      return (
                        <Image
                          width={149}
                          height={149}
                          alt={designerInfo?.name}
                          className="hidden h-full min-h-[165px] w-full lg:block lg:min-w-[178px]"
                          key={`desinger-product-${index}`}
                          imageClassName="h-full  object-cover"
                          src={image}
                        />
                      );
                    })}

                    {mobileImages.map((image, index) => {
                      if (!image) return null;
                      return (
                        <Image
                          width={149}
                          height={149}
                          alt={designerInfo?.name}
                          className="h-full min-h-[165px] w-full lg:hidden"
                          key={`desinger-product-${index}`}
                          imageClassName="h-full  object-cover"
                          src={image}
                        />
                      );
                    })}
                  </div>
                  {button.label && button.url && (
                    <Link
                      className="mx-auto w-full lg:max-w-[273px]"
                      href={button.url}
                    >
                      <Button
                        className="!h-10 w-full lg:px-0"
                        appearance="outlined"
                      >
                        {button.label}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
              {designers?.length - 1 !== index && (
                <hr
                  className={clsx(
                    'mb-10 block w-screen border-t border-lightBlue-500 lg:hidden',
                    {
                      'lg:col-start-1 lg:col-end-4 lg:ml-[calc(calc(-100vw_-_1296px)_/_2)]  lg:!block lg:w-[calc(calc(100vw_+_1296px))]':
                        index !== 0 && (index + 1) % 3 === 0
                    }
                  )}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </section>
  );
}
