'use-client';
import Tabs from './tabs';
import Banners from './banners';
import { BannersData, TabsData } from './type';

type Props = {
  tabs: TabsData;
  banners?: BannersData;
  setSelectedSlug: React.Dispatch<React.SetStateAction<string>>;
};

const Content = ({ tabs, banners, setSelectedSlug }: Props) => {
  return (
    <div>
      <Tabs setSelectedSlug={setSelectedSlug} tabs={tabs} className="mb-8" />
      <Banners data={banners} />
    </div>
  );
};

export default Content;
