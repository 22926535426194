'use client';
import { Link } from '@akinon/next/components/link';
import { Image } from '@akinon/next/components/image';
import { Icon } from '@akinon/next/components/icon';
import { Banner, BannersData } from './type';
import { twMerge } from 'tailwind-merge';
import { Button } from '@theme/components';

type BannerProps = {
  data: Banner;
  isFirst?: boolean;
};

const Banner = ({ data, isFirst = false }: BannerProps) => {
  return (
    <Link
      href={data?.value?.link || '#'}
      className={twMerge('relative snap-center', isFirst && 'w-full md:w-auto')}
    >
      <Image
        src={data?.kwargs?.value?.image?.url}
        alt="Banner 1"
        aspectRatio={306 / 600}
        sizes="(max-width: 768px) 100vw, 306px"
        fill
        className={twMerge(
          'hidden md:block md:h-[600px] md:w-[306px]',
          isFirst && 'md:h-[600px] md:w-[306px]'
        )}
      />
      <Image
        src={data?.kwargs?.value?.mobile_image?.url}
        alt="Banner 1"
        aspectRatio={343 / 200}
        sizes="(max-width: 768px) 100vw, 343px"
        fill
        className={twMerge(
          'h-[150px] w-[150px] md:hidden',
          isFirst && 'h-[200px] w-full'
        )}
      />
      <div
        className={twMerge(
          'absolute left-5 top-5 text-white',
          isFirst &&
            'left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 text-center text-black'
        )}
      >
        <p
          className={twMerge(
            'text-base font-medium md:text-2xl',
            isFirst && 'mx-auto mb-8 max-w-[200px] text-lg font-normal'
          )}
        >
          {data.value.text}
        </p>
        {!isFirst && (
          <p className="flex w-fit items-center gap-4 text-base">
            <span className="border-b border-b-white pb-1">
              {data.value.link_text}
            </span>
            <Icon name="arrow-right" size={32} />
          </p>
        )}
        {isFirst && (
          <Button className="!h-[40px]"> {data.value.link_text} </Button>
        )}
      </div>
    </Link>
  );
};

type Props = {
  data: BannersData;
};

const Banners = ({ data }: Props) => {
  return (
    <div className="flex flex-col flex-wrap gap-[10px] md:flex-row md:flex-nowrap md:gap-6">
      <Banner isFirst data={data.banner_1} />
      <div className="flex w-[calc(100%+16px)] snap-x snap-proximity snap-center snap-always gap-[10px] overflow-x-scroll md:gap-6 md:overflow-auto ">
        <Banner data={data.banner_2} />
        <Banner data={data.banner_3} />
        <Banner data={data.banner_4} />
      </div>
    </div>
  );
};

export default Banners;
