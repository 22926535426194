'use client';

import clsx from 'clsx';
import { ReactNode } from 'react';
import { useMediaQuery } from '@akinon/next/hooks';
import { useEffect, useState } from 'react';
import { WidgetListItemType } from './types';

export const Container = ({
  children,
  widget
}: {
  children: ReactNode;
  widget: WidgetListItemType;
}) => {
  const matches = useMediaQuery('(min-width: 768px)');

  const [matchesStatus, setMatchesStatus] = useState(false);

  useEffect(() => {
    setMatchesStatus(matches);
  }, [matches]);

  const style = {};

  const className = {};

  if (widget.value.is_active === 'yes') {
    className['block'] = true;
  } else {
    return;
  }

  if (!matchesStatus) {
    if (widget.value.padding_top && widget.value.padding_bottom) {
      style['paddingTop'] = `${widget.value.padding_top}`;
      style['paddingBottom'] = `${widget.value.padding_bottom}`;
    }
  } else {
    if (widget.value.desktop_padding_bottom) {
      style['paddingTop'] = `${widget.value.desktop_padding_top}`;
      style['paddingBottom'] = `${widget.value.desktop_padding_bottom}`;
    }
  }

  return (
    <div
      className={widget.value.is_contained === 'yes' ? 'container' : undefined}
    >
      <div style={style} className={clsx(className ? className : undefined)}>
        {children}
      </div>
    </div>
  );
};
