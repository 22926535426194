'use client';
import { ImageBannerWidgetSecondType } from './type';
import clsx from 'clsx';
import { Button, Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';

export default function ImageBannerSecondWidget({
  data
}: {
  data: ImageBannerWidgetSecondType;
}) {
  const attributes = data.attributes;

  if (Object.keys(attributes).length === 0) return;

  const desktopImage = attributes?.image?.kwargs?.url;
  const mobileImage = attributes?.mobile_image?.kwargs?.url;
  const title = attributes?.title?.value;
  const buttonLabel = attributes?.button_label?.value;
  const buttonLink = attributes?.button_link?.value;

  return (
    <div
      className={clsx(
        'border-b border-lightBlue-500 py-10',
        'h-full lg:px-0 lg:py-[72px]'
      )}
    >
      <div className="container relative h-[400px] md:h-auto">
        {desktopImage && (
          <Image
            className="hidden lg:block"
            imageClassName="h-[268px] w-full object-cover"
            alt={title}
            sizes="100vw"
            src={desktopImage}
            width={1440}
            height={875}
          />
        )}
        {(mobileImage || desktopImage) && (
          <Image
            className="block h-full lg:hidden"
            imageClassName="w-full h-full object-cover"
            alt={title}
            sizes="100vw"
            src={mobileImage || desktopImage}
            width={750}
            height={1334}
          />
        )}
        <div
          className={clsx(
            'absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 transform px-10 py-10 text-left text-white',
            'lg:left-[72px] lg:translate-x-0 lg:px-0 lg:py-[72px] lg:text-left'
          )}
        >
          <div className="mb-8">
            {title && (
              <h4
                className={clsx(
                  'text-3xl leading-[1.33] lg:inline',
                  'lg:text-[32px] lg:leading-[1.38]'
                )}
              >
                {title}
              </h4>
            )}
          </div>
          {buttonLabel && (
            <Link href={buttonLink}>
              <Button className="h-12 bg-white text-primary">
                {buttonLabel}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
