'use client';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { TabsData } from './type';

type Props = {
  className?: string;
  tabs: TabsData;
  setSelectedSlug: React.Dispatch<React.SetStateAction<string>>;
};

const Tabs = ({ className, tabs, setSelectedSlug }: Props) => {
  const [activeTab, setActiveTab] = useState(tabs[0].slug);

  const selectTabHandler = (slug: string) => {
    setActiveTab(slug);
    setSelectedSlug(slug);
  };

  return (
    <div className="relative flex items-center gap-2">
      {tabs.map((tab) => {
        return (
          <button
            key={tab.slug}
            onClick={() => selectTabHandler(tab.slug)}
            className={twMerge(
              'border-b-[2px] border-transparent md:px-2 pb-2 text-sm md:text-lg font-medium leading-7 text-gray-700',
              tab.slug === activeTab &&
                'border-primary font-medium text-primary',
              className
            )}
          >
            {tab.tab}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
