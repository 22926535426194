'use client';
import { Image } from '@akinon/next/components/image';
import {
  Button,
  Icon,
  Link,
  SwiperReact,
  SwiperSlide
} from '@theme/components';

import { useInView } from 'react-intersection-observer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { pushViewPromotion, pushSelectPromotion } from '@theme/utils/gtm';
import { HomeInspirationMasonryType } from './type';
import clsx from 'clsx';
import { useMediaQuery } from '@akinon/next/hooks';

const HomeInspirationItem = ({
  item,
  linkClassName = '',
  itemWidth,
  itemHeigth,
  mobileImageWidth,
  mobileImageHeigth,
  descriptionClassName = 'my-3 text-xl font-medium',
  imageClassName = 'object-cover',
  index
}) => {
  const { ref, inView } = useInView();
  const [viewed, setViewed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const promotionEvent = useMemo(
    () => ({
      creative_name: item?.kwargs?.value?.image?.url,
      creative_slot: `Home Inspiration ${index + 1}`,
      promotion_id: `home-inspiration_${index + 1}`,
      promotion_name: item?.value?.description || ''
    }),
    [item, index]
  );

  useEffect(() => {
    if (inView && !viewed) {
      setViewed(true);
      pushViewPromotion(promotionEvent);
    }
  }, [inView, viewed, promotionEvent]);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  const video = item?.kwargs?.value;

  return (
    <>
      {video?.desktop_video_file?.url &&
      video?.mobile_video_file?.url &&
      item?.value?.photo_or_video &&
      (item?.value?.photo_or_video === 'true' || item?.value?.photo_or_video === 'both') ? (
        <div
          className={clsx('relative h-auto', {
            '[&:hover>button]:visible [&:hover>button]:opacity-100': isPlaying
          })}
        >
          <video
            ref={videoRef}
            loop
            muted
            autoPlay={false}
            className={clsx('h-full w-full object-cover')}
            width={itemWidth}
            height={itemHeigth}
            src={
              isMobile
                ? video?.desktop_video_file?.url
                : video?.mobile_video_file?.url
            }
          ></video>
          {(item?.value?.photo_or_video === 'both' && item?.kwargs?.value?.desktopImage?.url && item?.kwargs?.value?.mobileImage?.url) && (
            <>
            <Image
              src={item?.kwargs?.value?.desktopImage?.url ?? '/noimage.jpg'}
              alt="home-inspiration"
              imageClassName={clsx('hidden h-full md:block', imageClassName)}
              className={clsx(
                "h-full w-full absolute top-0 left-0 z-[1]",
                isVideoPlayed && 'hidden'
              )}
              width={itemWidth}
              height={itemHeigth}
            />
            <Image
              src={item?.kwargs?.value?.mobileImage?.url ?? '/noimage.jpg'}
              alt="home-inspiration"
              className={clsx(
                "h-full w-full absolute top-0 left-0 z-[1]",
                isVideoPlayed && 'hidden'
              )}
              imageClassName={clsx('block md:hidden w-full h-full', imageClassName)}
              width={mobileImageWidth}
              height={mobileImageHeigth}
            />
            </>
          )}
          <Button
            onClick={() => {
              setIsPlaying((isPlaying) => !isPlaying);
              setIsVideoPlayed(true);
            }}
            className={clsx(
              'absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform transition-all delay-200 duration-100',
              'grid h-12 w-12 place-items-center rounded-full p-0',
              { 'invisible opacity-0': isPlaying }
            )}
          >
            {!isPlaying ? (
              <Icon
                className="ml-1 justify-center lg:!text-4xl"
                name="play"
                size={32}
              />
            ) : (
              <div className="flex gap-1">
                <span className="h-3 w-[1px] bg-white"></span>
                <span className="h-3 w-[1px] bg-white"></span>
              </div>
            )}
          </Button>
        </div>
      ) : (
        <Link
          href={item?.value?.url}
          className={linkClassName}
          onClick={() => pushSelectPromotion(promotionEvent)}
        >
          <div ref={ref} className="flex h-full flex-col justify-between">
            <Image
              src={item?.kwargs?.value?.desktopImage?.url ?? '/noimage.jpg'}
              alt="home-inspiration"
              imageClassName={clsx('hidden h-full md:block', imageClassName)}
              className="h-full w-full"
              width={itemWidth}
              height={itemHeigth}
            />
            <Image
              src={item?.kwargs?.value?.mobileImage?.url ?? '/noimage.jpg'}
              alt="home-inspiration"
              imageClassName={clsx('block md:hidden', imageClassName)}
              width={mobileImageWidth}
              height={mobileImageHeigth}
            />
            {item?.value?.description && (
              <p className={descriptionClassName}>
                {item?.value?.description}{' '}
                <Icon
                  className="inline-block align-middle"
                  size={20}
                  name="arrow-right"
                />
              </p>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

export default function HomeInspirationMasonry({
  data
}: {
  data: HomeInspirationMasonryType;
}) {
  if (Object.keys(data || {}).length === 0) return null;

  const {
    title,
    button_url,
    button_text,
    home_inspiration_items,
    home_inspiration_showcase_first,
    home_inspiration_showcase_second,
    home_inspiration_showcase_third
  } = data?.attributes ?? {};

  const mergedItems = [
    home_inspiration_showcase_first,
    home_inspiration_showcase_second,
    home_inspiration_showcase_third,
    ...home_inspiration_items
  ];

  if (mergedItems && mergedItems.length === 0) return null;

  return (
    <div className="border-t border-lightBlue py-10 md:p-[72px]">
      <div className="mb-6 flex flex-col items-center justify-center gap-4 md:mb-[55px]">
        <h2 className="px-8 text-center text-2xl leading-[1.33] tracking-[-0.4px] md:text-[36px] md:leading-[1.3]">
          {title?.value}
        </h2>
        <Link
          href={button_url?.value}
          className="flex items-center justify-center gap-1 text-sm md:text-base"
        >
          <div className="border-b border-primary pb-0.5 text-sm lg:text-base">
            {button_text?.value}
          </div>
          <Icon name={'arrow-right'} />
        </Link>
      </div>

      <div className="block md:hidden">
        <SwiperReact
          spaceBetween={16}
          slidesPerView={1.4}
          breakpoints={{
            575: {
              slidesPerView: 2
            }
          }}
        >
          {mergedItems?.map((item, index) => (
            <SwiperSlide
              key={index}
              className="flex-shrink-0 first:pl-4 last:pr-4"
            >
              <HomeInspirationItem
                item={item}
                itemWidth={282}
                itemHeigth={352}
                mobileImageWidth={282}
                mobileImageHeigth={352}
                imageClassName="object-cover min-w-[232px] min-h-[352px]"
                descriptionClassName="mt-3 text-base font-medium"
                index={0}
              />
            </SwiperSlide>
          ))}
        </SwiperReact>
      </div>

      <div className="container hidden md:block">
        <div className="grid grid-cols-2 gap-6">
          <HomeInspirationItem
            item={home_inspiration_showcase_first}
            itemWidth={636}
            itemHeigth={494}
            mobileImageWidth={282}
            mobileImageHeigth={352}
            linkClassName={'col-span-1'}
            index={0}
          />
          <div className="col-span-1 grid grid-rows-2 gap-6">
            <HomeInspirationItem
              item={home_inspiration_showcase_second}
              itemWidth={636}
              itemHeigth={208}
              mobileImageWidth={258}
              mobileImageHeigth={352}
              index={1}
            />
            <HomeInspirationItem
              item={home_inspiration_showcase_third}
              itemWidth={636}
              itemHeigth={208}
              mobileImageWidth={258}
              mobileImageHeigth={352}
              index={2}
            />
          </div>
        </div>

        <SwiperReact
          className="mt-6"
          spaceBetween={24}
          breakpoints={{
            768: {
              slidesPerView: 3
            },
            1024: {
              slidesPerView: 4
            }
          }}
        >
          {home_inspiration_items?.map((item, index) => (
            <SwiperSlide key={index} className="flex-shrink-0">
              <HomeInspirationItem
                item={item}
                itemWidth={306}
                itemHeigth={306}
                mobileImageWidth={258}
                mobileImageHeigth={352}
                descriptionClassName="mt-3 text-base font-medium"
                index={index + 3}
              />
            </SwiperSlide>
          ))}
        </SwiperReact>
      </div>
    </div>
  );
}
