'use client';

import { Product } from '@akinon/next/types';
import { Button, Icon, SwiperReact } from '@theme/components';
import { ProductItem } from '@theme/views/product-item';
import clsx from 'clsx';
import { useRef } from 'react';
import { SwiperSlide } from 'swiper/react';

export function CombinationRecommendationProducts({
  products
}: {
  products: Product[];
}) {
  const swiperRef = useRef(null);
  if (!Array.isArray(products) || !products.length) return;

  return (
    <SwiperReact
      ref={swiperRef}
      slidesPerView={1.915}
      className={clsx(
        'w-full !overflow-visible !px-4 lg:!px-0',
        '[&:not(.swiper-initialized)_.swiper-slide]:mr-4 [&:not(.swiper-initialized)_.swiper-slide]:max-w-[200px]',
        '[&:not(.swiper-initialized)_.swiper-slide]lg:mr-6 [&:not(.swiper-initialized)_.swiper-slide]:lg:max-w-[306px]'
      )}
      spaceBetween={16}
      breakpoints={{
        768: {
          slidesPerView: 3,
          spaceBetween: 24
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 24
        }
      }}
    >
      {products?.map((product, index) => (
        <SwiperSlide key={`combination-recommendation-product-${product.pk}`}>
          <ProductItem
            product={product}
            key={product?.pk}
            width={340}
            height={510}
            index={index}
          />
        </SwiperSlide>
      ))}
      <Button
        onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
        className={clsx(
          'grid !h-8 !min-h-8 !min-w-8 max-w-8 items-center rounded-full outline-0',
          'border-0 bg-gray-100 p-0 text-primary',
          'absolute left-4 top-1/2 z-30 translate-y-1/2 transform'
        )}
      >
        <Icon className="justify-center" name="chevron-start" size={14} />
      </Button>
      <Button
        onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
        className={clsx(
          'grid !h-8 !min-h-8 !min-w-8 max-w-8 items-center rounded-full outline-0',
          'border-0 bg-gray-100 p-0 text-primary',
          'absolute right-4 top-1/2 z-30 translate-y-1/2 transform'
        )}
      >
        <Icon className="justify-center" name="chevron-end" size={14} />
      </Button>
    </SwiperReact>
  );
}
