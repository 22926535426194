'use client';
import { Image } from '@akinon/next/components/image';
import { CampusAndInternshipType } from './type';
import { Button, Link, Icon } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';

export default function CampusAndInternshipWidget({
  data
}: {
  data: CampusAndInternshipType;
}) {
  const { t } = useLocalization();
  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return;

  const mainTitle = attributes?.main_title?.value;
  const mainDescription = attributes?.main_description?.value;
  const image = attributes?.image?.kwargs?.url;
  const mobileImage = attributes?.mobile_image?.kwargs?.url;
  const buttonUrl = attributes?.button_url?.value;

  return (
    <div className="mt-10 border-b border-lightBlue font-sans text-primary md:mt-[4.375rem]">
      <div className="container flex flex-col gap-4 pb-[2.438rem] md:grid md:grid-cols-[34.375rem_1fr] md:flex-row md:gap-24 md:pb-[4.375rem]">
        {image && (
          <Image
            imageClassName="w-full object-cover"
            className={'hidden lg:block'}
            alt={mainTitle}
            src={image}
            width={550}
            height={568}
          />
        )}
        {mobileImage && (
          <Image
            imageClassName="w-full object-cover"
            className={'lg:hidden'}
            alt={mainTitle}
            src={mobileImage}
            width={343}
            height={248}
          />
        )}
        <div className="flex flex-col gap-4">
          {mainTitle && (
            <div className="text-2xl leading-6 md:text-[2.5rem]  md:leading-snug">
              {mainTitle}
            </div>
          )}
          {mainDescription && (
            <div
              className="text-sm leading-snug md:text-base"
              dangerouslySetInnerHTML={{ __html: mainDescription }}
            ></div>
          )}
          <Link href={buttonUrl} className="mt-4 w-full">
            <Button className="flex !h-12 w-full items-center justify-center gap-2 !text-base leading-6 !text-white md:w-auto">
              {t('flat_pages.career.button_type_two')}
              <Icon name="arrow-right" size={24} />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
