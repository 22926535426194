'use client';

import { Image } from '@akinon/next/components/image';
import { Button, Icon } from '@theme/components';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

type Props = {
  url: string;
  thumbnail: string;
  mobileThumbnail: string;
};

export function GridVideo(video: Props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hideThumbnail, setHideThumbnail] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <>
      {video.url && (
        <div
          className={clsx(
            'h-auto',
            {
              '[&:hover>button]:visible [&:hover>button]:opacity-100': isPlaying
            },
            {
              'h-full': hideThumbnail
            }
          )}
        >
          <video
            ref={videoRef}
            loop
            muted
            autoPlay={false}
            className={clsx('h-full w-full object-cover', {
              hidden: !hideThumbnail
            })}
            src={video.url}
          ></video>
          <Button
            onClick={() => {
              setIsPlaying((isPlaying) => !isPlaying);
              setHideThumbnail(true);
            }}
            className={clsx(
              'absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform transition-all delay-200 duration-100',
              'grid h-12 w-12 place-items-center rounded-full p-0',
              { 'invisible opacity-0': isPlaying }
            )}
          >
            {!isPlaying ? (
              <Icon
                className="ml-1 justify-center lg:!text-4xl"
                name="play"
                size={32}
              />
            ) : (
              <div className="flex gap-1">
                <span className="h-3 w-[1px] bg-white"></span>
                <span className="h-3 w-[1px] bg-white"></span>
              </div>
            )}
          </Button>
        </div>
      )}
      {video?.thumbnail && !hideThumbnail && (
        <Image
          className="hidden  h-full w-full lg:block"
          imageClassName="w-full h-full object-cover lg:max-h-full"
          width={363}
          height={624}
          alt="grid-with-video"
          src={video?.thumbnail}
        />
      )}
      {video?.mobileThumbnail && !hideThumbnail && (
        <Image
          className="h-full w-full lg:hidden"
          imageClassName="w-full h-full object-cover max-h-[330px]"
          width={343}
          height={330}
          alt="grid-with-video"
          src={video?.mobileThumbnail}
        />
      )}
    </>
  );
}
