'use client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Input,
  LoaderSpinner,
  Select,
  Checkbox
} from '@theme/components';
import { useSession } from 'next-auth/react';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  useGetContactSubjectsQuery,
  useSendContactMutation
} from '@akinon/next/data/client/account';
import * as yup from 'yup';
import { useLocalization } from '@akinon/next/hooks';
import { Modal } from '@theme/components';
import { ContactFormType } from '@theme/types';
import contactImage from '@theme/assets/images/contact-image.webp';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';
import TextArea from '@theme/components/text-area';
import { twMerge } from 'tailwind-merge';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';

const contactFormSchema = (t) =>
  yup.object().shape({
    first_name: yup.string().required(t('account.contact.form.error.required')),
    last_name: yup.string().required(t('account.contact.form.error.required')),
    email: yup
      .string()
      .email(t('account.contact.form.error.email_valid'))
      .required(t('account.contact.form.error.required')),
    phone: yup
      .string()
      .transform((value: string) =>
        value.replace(/^\+9/, '').replace(/[()\s\-_]/g, '')
      )
      .length(11, t('account.contact.form.error.phone_length'))
      .required(t('account.contact.form.error.required')),
    subject: yup.string().required(t('account.contact.form.error.required')),
    message: yup
      .string()
      .required(t('account.contact.form.error.required'))
      .min(10, t('account.contact.form.error.message_length')),
    info_confirm: yup
      .boolean()
      .oneOf([true], t('auth.register.form.error.required')),
    kvkk_confirm: yup
      .boolean()
      .oneOf([true], t('auth.register.form.error.required'))
  });

const ContactForm = () => {
  const { t } = useLocalization();
  const { data: session, status } = useSession();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const {
    data: contactSubject,
    isLoading: subjectLoading,
    isSuccess: subjectSuccess
  } = useGetContactSubjectsQuery();

  const { data: kvkk } = useGetWidgetQuery('kvkk-agreement');

  const [sendContact, { isSuccess: formSuccess }] = useSendContactMutation();

  const filteredSubjects = useMemo(() => {
    if (subjectSuccess) {
      const options = [
        { label: t('account.contact.form.subject.placeholder'), value: '' }
      ];
      contactSubject
        ?.filter((item) =>
          status === 'unauthenticated' ? !item.is_order_needed : item
        )
        .forEach((item) => {
          options.push({ label: item.text, value: item.id.toString() });
        });
      return options;
    }
    return [];
  }, [subjectSuccess, contactSubject, status, t]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<ContactFormType>({
    resolver: yupResolver(contactFormSchema(t))
  });

  const onSubmit: SubmitHandler<ContactFormType> = (data) => {
    const formData = new FormData();
    formData.append('full_name', `${data.first_name} ${data.last_name}`);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('message', data.message);
    formData.append('subject', data.subject);

    sendContact(formData)
      .then(() => {
        reset();
        setSuccessModalOpen(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className="block justify-center shadow-sm md:flex">
      <div className="flex w-full flex-col lg:w-auto lg:bg-white">
        {formSuccess && (
          <Modal
            portalId="form-success-modal"
            open={isSuccessModalOpen}
            title={t('account.contact.form.success.title')}
            setOpen={(state) => {
              setSuccessModalOpen(state);
            }}
            titleClassName="text-2xl font-medium leading-8"
            className="bottom-0 top-auto w-full max-w-lg translate-y-0 p-6 md:bottom-auto md:top-1/2 md:-translate-y-1/2"
            classNameHeader="p-0 pb-4 border-b-0"
          >
            <div className="max-h-[85vh] overflow-y-auto pb-8">
              <div>{t('account.contact.form.success.description')}</div>
            </div>
            <Button
              onClick={() => setSuccessModalOpen(false)}
              className="h-12 w-full px-4 py-3 font-medium"
            >
              {t('account.contact.form.success.button')}
            </Button>
          </Modal>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={clsx('mx-4 my-10 gap-4', 'lg:mx-10 lg:flex-row lg:gap-4')}
          noValidate
        >
          <div>
            <div className="mb-2 text-lg font-medium md:text-2xl">
              {t('account.contact.header.title')}
            </div>
            <p className="mb-8 text-sm text-primary-500 md:text-base">
              {t('account.contact.header.subtitle')}
            </p>
          </div>
          <div className="flex">
            <div className="mb-5 w-1/2 pr-2">
              <Input
                label={t('account.contact.form.first_name.label')}
                placeholder={t('account.contact.form.first_name.placeholder')}
                className="mb-1"
                inputClassName="!text-base !leading-6 !px-4 !py-2 placeholder:text-sm"
                labelClassName="text-gray-800 md:leading-5"
                {...register('first_name')}
                error={errors.first_name}
                required
              />
            </div>
            <div className="mb-5 w-1/2 pl-2">
              <Input
                label={t('account.contact.form.last_name.label')}
                placeholder={t('account.contact.form.last_name.placeholder')}
                labelClassName="text-gray-800 md:leading-5"
                className="mb-1"
                inputClassName="!text-base !leading-6 !px-4 !py-2 placeholder:text-sm"
                {...register('last_name')}
                error={errors.last_name}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mb-5 w-full pr-0 md:w-1/2 md:pr-2">
              <Input
                label={t('account.contact.form.email.label')}
                placeholder={t('account.contact.form.email.placeholder')}
                type="email"
                labelClassName="text-gray-800 md:leading-5"
                className="mb-1"
                inputClassName="!text-base !leading-6 !px-4 !py-2 placeholder:text-sm"
                {...register('email')}
                error={errors.email}
                required
              />
            </div>
            <div className="mb-5 w-full pl-0 md:w-1/2 md:pl-2">
              <Input
                label={t('account.contact.form.phone.title')}
                type="tel"
                inputClassName="!text-base !leading-6 !px-4 !py-2"
                className="mb-1"
                labelClassName="text-gray-800 md:leading-5"
                mask="_"
                format="0 (###) ### ## ##"
                allowEmptyFormatting={true}
                control={control}
                {...register('phone')}
                error={errors.phone}
                required
              />
            </div>
          </div>

          <div className="relative mb-5">
            {subjectLoading && <LoaderSpinner />}
            {subjectSuccess && (
              <Select
                name="subject"
                control={control}
                {...register('subject', {
                  onChange: (e) => {
                    setValue('subject', e?.currentTarget?.value);
                  }
                })}
                options={filteredSubjects}
                error={errors.subject}
                className="mb-1 w-full border-gray-500 text-sm"
                labelClassName="text-gray-800 md:leading-5"
                label={t('account.contact.form.subject.title')}
                iconSize={10}
                required
              />
            )}
          </div>

          <TextArea
            requiredClassName="text-gray-800"
            counterClassName={twMerge('text-sm', errors.message && 'bottom-7')}
            labelClassName="mb-2 block text-sm text-gray-800"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            {...register('message')}
            label={t('account.contact.form.message.title')}
            className={clsx(
              ' w-full border border-gray-500 p-2.5 text-xs placeholder:text-sm placeholder:text-gray-700 hover:border-black focus:border-black focus-visible:outline-none'
            )}
            required
            maxLength={280}
            characterCount
            errorClassName="static"
            error={errors.message}
            rows={7}
            placeholder={t('account.contact.form.message.placeholder')}
          />
          <Checkbox
            inputClassName="!w-[15px] md:!w-[11px] !h-[15px] md:!h-[11px]"
            labelClassName="text-black text-xs font-normal"
            required
            error={errors.info_confirm}
            {...register('info_confirm')}
          >
            {t('account.contact.form.checkboxes.title')}
          </Checkbox>
          <div className="mt-[19px] flex items-center gap-x-[10px] pb-5 text-black">
            <Checkbox
              inputClassName="!w-[15px] md:!w-[11px] !h-[15px] md:!h-[11px]"
              labelClassName="text-black text-xs font-normal"
              {...register('kvkk_confirm')}
              error={errors.kvkk_confirm}
            >
              <div className="underline" onClick={() => setIsModalOpen(true)}>
                {t('account.contact.form.checkboxes.link')}
              </div>
            </Checkbox>
          </div>
          <Button type="submit" className="w-full font-medium">
            {t('account.contact.form.submit_button')}
          </Button>
        </form>

        <Modal
          portalId="contact-form-modal"
          open={isModalOpen}
          title={kvkk?.attributes?.title?.value}
          setOpen={(state) => {
            setIsModalOpen(state);
          }}
          className="w-full max-w-xl"
        >
          <div
            className="max-h-80 overflow-auto p-4"
            dangerouslySetInnerHTML={{
              __html: kvkk?.attributes?.description?.value
            }}
          />
        </Modal>
      </div>

    </div>
  );
};

export default ContactForm;
